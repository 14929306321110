import { Component, Inject, Injector, HostListener, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { TaskGroupResultDto } from "../../models/TaskGroupResultDto";
import { TaskGroupDto } from "../../models/TaskGroupDto";
import { TaskItemResultDto } from "../../models/TaskItemResultDto";
import { ResultState, Role, CourseResultState, TaskType } from "../../models/enum";
import { ConfirmDialogModel, DialogType } from "../../common/confirmation-dialog/confirmation-dialog.component";
import { NotificationService } from "../../common/notification.service";
import { DialogService } from "../../common/dialog.service";
import { Constants } from "../../models/constants";
import { UserDto } from "../../models/UserDto";
import { TaskGroupResultService } from "../../services/task-group-result.service";
import { CourseResultExtendedDto } from "../../models/CourseResultExtendedDto";
import { TooltipConstants } from "../../common/tooltip.constants";

export class TaskGroupData {
  courseResult: CourseResultExtendedDto;
  result: TaskGroupResultDto;
  taskGroups: TaskGroupDto[];
  user: UserDto;
}

@Component({
  selector: 'task-group',
  templateUrl: './task-group.component.html',
  styleUrls: ['./task-group.component.scss']
})
export class TaskGroupComponent {

  private taskGroupsByType: { [type: string]: Array<TaskGroupDto>; } = {};

  public typeSelectForm = new FormGroup({});

  public dynamicForm = new FormGroup({});

  @Input() data: TaskGroupData;
  @Output() messageEvent = new EventEmitter<TaskGroupResultDto>();

  constructor(public constants: Constants, public tooltipConst: TooltipConstants, private dialogService: DialogService, private service: TaskGroupResultService, private formBuilder: FormBuilder, private notifier: NotificationService) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["data"] && this.data != null) {
      if (this.data.result != null) {
        this.generateDynamicForm(this.data.result);
      }
      //new
      else if (this.data.taskGroups != null && this.data.taskGroups.length > 0) {
        this.generateGroupTypes();
        this.generateTypeSelectForm();
      }
    }
  }

  public isInRole(role: string) {
    if (this.data.user === null) return false;
    return this.data.user.role.toString() === role;
  }

  public isFiled(): boolean {
    if (this.data.result !== null)
      return this.data.result.state === ResultState.Filed.toString();
    return false;
  }

  public isReadOnly(): boolean {
    if (!this.data.user || !this.data.result) return true;

    if (this.data.courseResult.state !== CourseResultState.Active.toString()) return true;

    if (this.data.user.role === Role.Student.toString())
      return this.data.result.state === ResultState.Filed.toString() || this.data.result.state === ResultState.Signed.toString();

    if (this.data.user.role === Role.Teacher.toString())
      return true;

    if (this.data.user.role === Role.Administrator.toString() || this.data.user.role === Role.Supervisor.toString())
      return this.data.result.taskGroup.groupType === TaskType.Absence.toString() || !this.data.result.taskGroup.isSupervised || this.data.result.state === ResultState.Signed.toString() || this.data.result.state !== ResultState.Filed.toString();
  }

  public getGroupTypes(): string[] {
    return Object.keys(this.taskGroupsByType);
  }

  public getGroups(): TaskGroupDto[] {
    if (this.typeSelectForm.controls['typeSelector'].valid)
      return this.taskGroupsByType[this.typeSelectForm.controls['typeSelector'].value];
    return [];
  }

  public onTypeSelected(): void {
    if (this.typeSelectForm.controls['typeSelector'].valid && this.typeSelectForm.controls['typeSelector'].value === "Absence") {
      this.generateQuestions(this.taskGroupsByType[this.typeSelectForm.controls['typeSelector'].value][0]);
      this.generateDynamicForm(this.data.result);
    }
  }

  public onGroupSelected(): void {
    if (this.typeSelectForm.controls["tgSelector"].value.isSupervised && !this.data.courseResult.supervisor) {
      this.notifier.showError("Välj en handledare, innan du kan fortsätta");
      return;
    }
    //generate questions
    if (this.typeSelectForm && this.typeSelectForm.valid) {
      this.generateQuestions(this.typeSelectForm.controls["tgSelector"].value);
      this.generateDynamicForm(this.data.result);
    }
  }

  onSubmit() {
    this.data.result.state = ResultState.Filed.toString();
    const dialogRef = this.dialogService.openConfirmationDialog(new ConfirmDialogModel("Lämna in...",
      "Är du säker på att du vill lämna in uppgiften?",
      DialogType.Confirmation));

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.data.result.id)
          this.updateResult();
        else
          this.createResult();
      }
    });
  }

  public onCreate(): void {
    this.data.result.state = ResultState.Started.toString();
    this.createResult();
  }

  public onSave(): void {
    this.updateResult();
  }

  public onReject(): void {
    this.data.result.state = ResultState.Rejected.toString();
    this.updateResult();
  }

  public onSign(): void {
    this.data.result.state = ResultState.Signed.toString();
    this.updateResult();
  }

  public startDateFilter = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  public endDateFilter = (d: Date): boolean => {
    const day = d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  private generateQuestions(selectedTaskGroup: TaskGroupDto) {
    this.data.result = new TaskGroupResultDto();
    this.data.result.taskGroup = selectedTaskGroup;//this.typeSelectForm.controls["tgSelector"].value;
    this.data.result.taskResults = new Array<TaskItemResultDto>();
    this.data.result.startDate = new Date();
    this.data.result.endDate = new Date();
    this.data.result.state = ResultState.Started.toString();
    if (this.data.result.taskGroup.isSupervised && this.data.courseResult.supervisor)
      this.data.result.supervisor = this.data.courseResult.supervisor;

    for (let ti of this.data.result.taskGroup.taskItems) {
      this.data.result.taskResults.push(
        {
          id: null,
          originalQuery: ti.query,
          taskItem: ti,
          answer: '',
        }
      );
    }
  }

  private createResult(): void {
    this.readInput();
    for (let tr of this.data.result.taskResults) {
      tr.originalQuery = tr.taskItem.query;
    }

    const updated = Object.assign({}, this.data.result);
    this.service.create(this.data.courseResult.id, updated).subscribe(result => {
      if (result) {
        this.notifier.showSuccess("Moment skapat");
        this.messageEvent.emit(result);
      }
    });
  }

  private updateResult(): void {
    this.readInput();
    const updated = Object.assign({}, this.data.result);
    this.service.update(updated).subscribe(result => {
      if (result) {
        this.notifier.showSuccess("Moment uppdaterat");
        this.messageEvent.emit(result);
      }
    });
  }

  private readInput(): void {
    this.data.result.startDate = this.dynamicForm.controls["startDate"].value;
    this.data.result.endDate = this.dynamicForm.controls["endDate"].value;
    for (let task of this.data.result.taskResults) {
      task.answer = this.dynamicForm.controls[task.taskItem.id].value;
    }
  }

  private generateTypeSelectForm(): void {
    this.typeSelectForm = new FormGroup({});
    const group = {};
    group["typeSelector"] = new FormControl({ value: undefined, disabled: false }, Validators.required);
    group["tgSelector"] = new FormControl({ value: undefined, disabled: false }, Validators.required);

    this.typeSelectForm = this.formBuilder.group(group);
  }

  private generateDynamicForm(result: TaskGroupResultDto) {
    this.dynamicForm = new FormGroup({}); // clear
    const group = {};

    group["startDate"] = new FormControl({ value: new Date(result.startDate), disabled: this.isReadOnly() || !this.isInRole("Student") }, Validators.required);
    group["endDate"] = new FormControl({ value: new Date(result.endDate), disabled: this.isReadOnly() || !this.isInRole("Student") }, Validators.required);

    for (let task of result.taskResults) {
      switch (task.taskItem.taskType) {
        case "Text":
          {
            group[task.taskItem.id] = new FormControl({ value: task.answer || '', disabled: this.isReadOnly() || !this.isInRole("Student") });
            break;
          }
        case "Amount":
          {
            group[task.taskItem.id] = new FormControl({ value: task.answer || '', disabled: this.isReadOnly() || !this.isInRole("Student") }, [Validators.min(1), Validators.pattern("[0-9]*"),
            Validators.maxLength(2)]);
            break;
          }
        case "Happiness":
          {
            group[task.taskItem.id] = new FormControl({
              value: task.answer || '',
              disabled: this.isReadOnly() || !this.isInRole("Student")
            }, Validators.required);
            break;
          }
        default:
          {
            this.notifier.showError("Kan inte generera uppgift av typ: " + task.taskItem.taskType);
          }
      }
    }
    this.dynamicForm = this.formBuilder.group(group);
  }

  private generateGroupTypes() {
    this.taskGroupsByType = {};

    for (let tg of this.data.taskGroups) {
      if (this.taskGroupsByType[tg.groupType] == null)
        this.taskGroupsByType[tg.groupType] = new Array<TaskGroupDto>();
      if (tg.taskItems.length > 0)
        this.taskGroupsByType[tg.groupType].push(tg);
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEsc(event: KeyboardEvent) {
    this.messageEvent.emit(null);
    //this.dialogRef.close();
  }
}
