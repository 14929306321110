import { Component, OnInit, Injector, Inject, ViewChild } from '@angular/core';
import { AccountService } from "../services/account.service";
import { UserDto } from "../models/UserDto";
import { DialogService } from "../common/dialog.service";
import { ConfirmDialogModel, DialogType } from "../common/confirmation-dialog/confirmation-dialog.component";
import { NotificationService } from "../common/notification.service";
import { MatSort } from "@angular/material/sort";
import { MatPaginator}from "@angular/material/paginator";
import { MatTableDataSource} from "@angular/material/table";
import { TooltipConstants } from "../common/tooltip.constants";
import { Constants } from "../models/constants";

@Component({
    selector: 'user-manager',
    templateUrl: './user-manager.component.html',
    styleUrls: ['./user-manager.component.css']
})
export class UserManagerComponent implements OnInit {

    activeDataSource = new MatTableDataSource<UserDto>([]);

    inactiveDataSource = new MatTableDataSource<UserDto>([]);

    users: UserDto[] = [];

    activeUsers: UserDto[] = [];

    archivedUsers: UserDto[] = [];

    public tooltipConst = new TooltipConstants();

    public constants = new Constants();

    displayedColumns: string[] = ["role","ssn", "firstName", "lastName", "email", "phoneNbr",  "remove", "archive"];

    @ViewChild("paginatorActive", { static: true }) paginatorActive: MatPaginator;
    @ViewChild("paginatorInactive", { static: true }) paginatorInactive: MatPaginator;

    @ViewChild("sortActive", { static: true }) sortActive: MatSort;
    @ViewChild("sortInActive", { static: true }) sortInActive: MatSort;

    constructor(private accountService: AccountService,
        private dialogService: DialogService,
        private notifier: NotificationService) {

        this.accountService.getAll().subscribe(result => {
            if (result != null) {
                this.users = result;
                this.buildTableSources();
            }
        });
    }

    ngOnInit() {

    }

    receiveMessage($event: UserDto) {
        this.add($event);
        this.notifier.showSuccess("Användare registrerad");
    }

    public archiveUser(user: UserDto) {
        user.isActive = !user.isActive;
        this.accountService.updateUser(user).subscribe(response => {
            if (response) {
                this.update(response);
                response.isActive
                    ? this.notifier.showSuccess("Användare återställd")
                    : this.notifier.showSuccess("Användare arkiverad");
            }
        });
    }

    public deleteUser(user: UserDto) {
        const dialogRef = this.dialogService.openDeleteConfirmationDialog(new ConfirmDialogModel("Ta bort...",
            "Är du säker på att du vill ta bort användaren: " + user.firstName + " " + user.lastName,
            DialogType.Invalid));
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.accountService.deleteUser(user.id).subscribe(result => {
                    if (result) {
                        this.remove(user);
                        this.notifier.showSuccess("Användare borttagen");
                    }
                });
            }
        });
    }

    public applyActiveFilter(filterValue: string): void {
        this.activeDataSource.filter = filterValue.trim().toLowerCase();
        if (this.activeDataSource.paginator) {
            this.activeDataSource.paginator.firstPage();
        }
    }

    public applyInActiveFilter(filterValue: string): void {
        this.inactiveDataSource.filter = filterValue.trim().toLowerCase();
        if (this.inactiveDataSource.paginator) {
            this.inactiveDataSource.paginator.firstPage();
        }
    }

    private add(toAdd: UserDto) {
        //const copiedData = this.users.slice();
        //copiedData.push(toAdd);
        //this.users = copiedData;
        this.users.push(toAdd);
        this.buildTableSources();
    }

    private update(toUpdate: UserDto) {
        const copiedData = this.users.slice();
        let toReplace = copiedData.find(r => r.id === toUpdate.id);
        toReplace = toUpdate;
        this.users = copiedData;
        this.buildTableSources();
    }

    private remove(result: UserDto): void {
        const copiedData = this.users.slice();
        const index = copiedData.findIndex(r => r.id === result.id);
        if (index > -1)
            copiedData.splice(index, 1);
        this.users = copiedData;
        this.buildTableSources();
    }

    private sortUsers(): void {
        this.activeUsers = [];
        this.archivedUsers = [];
        for (let user of this.users) {
            if (user.isActive) this.activeUsers.push(user);
            else this.archivedUsers.push(user);
        }
    }

    private buildTableSources(): void {
        this.sortUsers();
        this.activeDataSource = new MatTableDataSource(this.activeUsers);
        this.activeDataSource.paginator = this.paginatorActive;
        this.activeDataSource.sort = this.sortActive;
        this.inactiveDataSource = new MatTableDataSource(this.archivedUsers);
        this.inactiveDataSource.paginator = this.paginatorInactive;
        this.inactiveDataSource.sort = this.sortInActive;
    }
}
