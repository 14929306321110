<mat-card style="margin-left: 5px; margin-right: 5px">
  <mat-card-header fxLayout="column" fxLayoutAlign="space-between start">
    <mat-card-title>Status: {{tooltipConst.getCourseResultState(fcResult.state.toString())}}</mat-card-title>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxFill>
      <div fxLayout="column" fxLayoutAlign="center start" fxFlex="30">
        <mat-card-subtitle style="margin-left: 15px">Aktiva veckor: {{amountOfWeeks}}</mat-card-subtitle>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between end" fxFlex="70">
        <div *ngIf="currentUser.role==='Student'" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <div fxLayout="column" fxLayoutAlign="center center">
            <mat-icon color="primary" [matBadge]="stateValues['Filed']" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupState('Filed')}}" [matBadgeHidden]="stateValues['Filed'] === 0">{{constants.getTaskStateIcon('Filed')}}</mat-icon>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center">
            <mat-icon color="primary" [matBadge]="stateValues['Started']" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupState('Started')}}" [matBadgeHidden]="stateValues['Started'] === 0">{{constants.getTaskStateIcon('Started')}}</mat-icon>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center">
            <mat-icon color="primary" [matBadge]="stateValues['Rejected']" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupState('Rejected')}}" [matBadgeHidden]="stateValues['Rejected'] === 0">{{constants.getTaskStateIcon('Rejected')}}</mat-icon>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center">
            <mat-icon color="primary" [matBadge]="stateValues['Signed']" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupState('Signed')}}" [matBadgeHidden]="stateValues['Signed'] === 0">{{constants.getTaskStateIcon('Signed')}}</mat-icon>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center">
            <mat-icon color="primary" [matBadge]="stateValues['Absence']" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupType('Absence')}}" [matBadgeHidden]="stateValues['Absence'] === 0">{{constants.getTaskGroupTypeIcon('Absence')}}</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <button mat-icon-button color="accent" (click)="toggleActiveState()" *ngIf="fcResult.state!=='Completed' && currentUser.role==='Administrator'">
      <mat-icon *ngIf="fcResult.state==='Inactive'" matTooltip="Aktivera">lock</mat-icon>
      <mat-icon *ngIf="fcResult.state==='Active'" matTooltip="Inaktivera">lock_open</mat-icon>
    </button>
    <mat-icon *ngIf="fcResult.state==='Completed'" color="accent">check</mat-icon>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div *ngIf="fcResult">
      <div style="margin-top: 15px">
        <mat-card>
          <mat-card-content>
            <h5>Omdöme</h5>
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px" *ngIf="canEvaluate()">
              <form [formGroup]="evaluation" fxFlex="80">
                <mat-form-field fxFill>
                  <mat-label *ngIf="evaluation.controls['text'].value?.length == 0">fyll i omdömme här</mat-label>
                  <textarea matInput formControlName="text" maxlength="1500"></textarea>
                  <mat-hint align="end">{{evaluation.controls['text'].value?.length || 0}}/1500</mat-hint>
                </mat-form-field>
              </form>
              <div *ngIf="!isReadOnly()" fxFlex="20">
                <button mat-button color="primary" (click)="onSave()" fxFlex>Spara</button>
                <button mat-button color="accent" (click)="onCompleted()" fxFlex>Godkänna</button>
              </div>
              <button mat-icon-button color="accent" *ngIf="isReadOnly()" fxFlex (click)="unLock()" [disabled]="fcResult.state==='Inactive'"><mat-icon>lock</mat-icon></button>
            </div>
            <div *ngIf="!canEvaluate() && evaluation.controls['text']">
              <mat-label>{{fcResult.evaluation}}</mat-label>
            </div>
          </mat-card-content>
        </mat-card>
        <div style="margin-top: 15px">
          <mat-divider></mat-divider>
        </div>
        <div style="margin-top: 30px" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
          <h5>Sammanfattning</h5>
          <button mat-flat-button matTooltip="Exportera till excel" color="accent" (click)="excelExport()">
            <!--file-excel.svg-->
            <img src="assets/microsoft-excel-bold.svg" width="24" height="24" />
          </button>
        </div>
        <table mat-table [dataSource]="typeValues" style="width: 100%">
          <ng-container matColumnDef="type">
            <tr style="overflow-wrap: break-word; word-wrap: break-word">
              <th mat-header-cell *matHeaderCellDef>Typ</th>
              <td mat-cell *matCellDef="let result"> {{result.type}} </td>
              <td mat-footer-cell *matFooterCellDef>Totalt:</td>
            </tr>
          </ng-container>
          <ng-container matColumnDef="amount">
            <tr>
              <th mat-header-cell *matHeaderCellDef>Antal</th>
              <td mat-cell *matCellDef="let result"> {{result.amount}} </td>
              <td mat-footer-cell *matFooterCellDef> {{getTotalByType()}} </td>
            </tr>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let result; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>
        <mat-paginator #paginator [pageSizeOptions]="[15, 30, 50]" [length]="typeValues.length" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </mat-card-content>
  <!--<mat-divider></mat-divider>
  <mat-card-actions>
    <mat-expansion-panel class="mat-elevation-z" hideToggle="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Detaljerat
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        <mat-icon color="accent">info</mat-icon>
      </mat-expansion-panel-header>
      <table mat-table [dataSource]="typeValues" style="width: 100%">
        <ng-container matColumnDef="type">
          <tr>
            <th mat-header-cell *matHeaderCellDef>Typ</th>
            <td mat-cell *matCellDef="let result"> {{result.type}} </td>
            <td mat-footer-cell *matFooterCellDef>Totalt:</td>
          </tr>
        </ng-container>
        <ng-container matColumnDef="amount">
          <tr>
            <th mat-header-cell *matHeaderCellDef>Antal</th>
            <td mat-cell *matCellDef="let result"> {{result.amount}} </td>
            <td mat-footer-cell *matFooterCellDef> {{getTotalByType()}} </td>
          </tr>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let result; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
      </table>
    </mat-expansion-panel>
  </mat-card-actions>-->
</mat-card>

