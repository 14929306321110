   import { TaskGroupDto } from './TaskGroupDto';

export class CourseDto   {
	public id: string;
	public name: string;
	public state: string;
	public startDate: Date;
	public endDate: Date;
	public taskGroups: TaskGroupDto[];
}
