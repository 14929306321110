<div fxLayout="column" fxLayoutAlign="space-between stretch">
  <div fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="5px" fxFlex="100" style="padding-left:15px; padding-right:15px; padding-top:25px">
    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="10px">
      <div class="mat-dialog-icon">
        <mat-icon fxFlex="10" fxLayoutAlign="start start">{{icon}}</mat-icon>
      </div>
      <h2 mat-dialog-title fxFlex="90" fxLayoutAlign="start center">{{data.title}}</h2>
    </div>
    <div mat-dialog-content style="margin:15px">
      <p>{{data.message}}</p>
    </div>
  </div>
  <mat-divider [inset]="false"></mat-divider>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px" style="margin-right:15px;margin-bottom:0px">
    <button mat-button (click)="onNoClick()" color="primary">Nej</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial color="accent">Ja</button>
  </div>
</div>

