<div fxLayout="column" fxFlex="100" style="margin-top: 10px">
  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <div fxLayout="column" fxFlex="35">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
        <mat-icon matSuffix style="font-size: smaller" color="accent">filter_list</mat-icon>
      </mat-form-field>
    </div>
  </div>
  <table mat-table [dataSource]="inactiveDataSource" style="margin-top: 0;" #sort="matSort" fxFlex="100" matSort>
    <ng-container matColumnDef="name">
      <tr>
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kurstitel</th>
        <td mat-cell *matCellDef="let course">{{course.name}} </td>
      </tr>
    </ng-container>
    <ng-container matColumnDef="startDate">
      <tr>
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Startdatum</th>
        <td mat-cell *matCellDef="let course">{{course.startDate | date : "yyyy-MM-dd"}} </td>
      </tr>
    </ng-container>
    <ng-container matColumnDef="endDate">
      <tr>
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Slutdatum</th>
        <td mat-cell *matCellDef="let course">{{course.endDate | date : "yyyy-MM-dd"}} </td>
      </tr>
    </ng-container>
    <ng-container matColumnDef="remove">
      <tr>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let course"><button mat-icon-button color="primary" (click)="onActivate(course)" matTooltip="Återställ"><mat-icon>archive</mat-icon></button></td>
      </tr>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let course; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator #paginator [pageSize]="10" [length]="courses.length" showFirstLastButtons></mat-paginator>
</div>
