<mat-card class="mat-elevation-z" *ngIf="fcResult">
  <mat-card-header>
    <div mat-card-avatar><mat-icon color="accent">school</mat-icon></div>
    <mat-card-title>
      {{fcResult.course.name}}
    </mat-card-title>
    <mat-card-subtitle>
      {{fcResult.course.startDate | date : "yyyy-MM-dd"}} - {{fcResult.course.endDate | date : "yyyy-MM-dd"}}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content style="padding-top: 10px; padding-bottom: 10px">
    <mat-tab-group dynamicHeight *ngIf="fcResult" (selectedTabChange)="tabChanged($event)">
      <mat-tab label="Logg">
        <div style="padding-top: 5px; padding-bottom: 5px">
          <result-list  [fcResult]="fcResult" [currentUser]="currentUser"></result-list>
        </div>
      </mat-tab>
      <mat-tab label="Översikt">
        <div style="padding-top: 5px; padding-bottom: 5px">
          <result-overview [fcResult]="fcResult" [currentUser]="currentUser" [currentTab] ="currentTab"></result-overview>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
