import { Component, OnInit, SimpleChanges } from '@angular/core';
import { MatTabChangeEvent, MatTab } from '@angular/material/tabs';
import { CourseResultService } from '../services/course-result.service';
import { NavBarDataService, IStudentMessage } from "../nav-bar/nav-bar-data.service";
import { UserDto } from "../models/UserDto";
import { CourseResultExtendedDto } from "../models/CourseResultExtendedDto";

@Component({
  selector: 'field-course-result',
  templateUrl: './field-course-result.component.html',
  styleUrls: ['./field-course-result.component.css']
})
export class FieldCourseComponent implements OnInit {

  currentUser: UserDto;

  currentTab: MatTab;

  resultGuid: string;

  fcResult: CourseResultExtendedDto;

  constructor(private dataService: NavBarDataService, private courseResultService: CourseResultService) {

  }

  ngOnInit() {
    this.dataService.currentMessage.subscribe(message => {
      if (message != null) {
        if (message as IStudentMessage) {
          this.currentUser = (message as IStudentMessage).currentUser;
          this.resultGuid = (message as IStudentMessage).courseResultId;
          this.getExtendedResult();
        } else
          this.getResult();
        }
    });
  }

  tabChanged(event: MatTabChangeEvent) {
    this.currentTab = event.tab;
  }

  private getResult() {
    if (this.resultGuid != null) {
      this.courseResultService.getUserResult(this.resultGuid).subscribe(result => {
        if (result != null) {
          this.fcResult = result as CourseResultExtendedDto;
        }
      });
    }
  }

  private getExtendedResult() {
    if (this.resultGuid != null) {
      this.courseResultService.getExtendedCourseResult(this.resultGuid).subscribe(result => {
        if (result != null) {
          this.fcResult = result as CourseResultExtendedDto;
        }
      });
    }
  }

}
