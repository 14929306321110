import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppInjector } from './app/services/injector.service'
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import './polyfills';


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

platformBrowserDynamic().bootstrapModule(AppModule).then((moduleRef) => {
  AppInjector.setInjector(moduleRef.injector);
});
