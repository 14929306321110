import { Component, OnInit, Input, SimpleChanges, Query, ViewChild } from '@angular/core';
import { CourseDto } from "../../models/CourseDto";
import { Constants } from "../../models/constants";
import { TaskGroupDto } from "../../models/TaskGroupDto";
import { FormControl, Validators } from "@angular/forms";
import { TaskType } from "../../models/enum";
import { TaskGroupService } from "../../services/task-group.service";
import { NotificationService } from "../../common/notification.service";
import { DialogService } from "../../common/dialog.service";
import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator}from "@angular/material/paginator";
import { MatTableDataSource} from "@angular/material/table";
import { TooltipConstants } from "../../common/tooltip.constants";

export interface IListItem {
    highlighted?: boolean;
    payload: TaskGroupDto;
    name: string;
    isMandatory: boolean;
    groupType: string;
}

@Component({
    selector: 'course-content',
    templateUrl: './course-content.component.html',
    styleUrls: ['.././course-manager.component.css']//./course-content.component.css
})
export class CourseContentComponent implements OnInit {

    public displayedColumns: string[] = ["groupType", "name", "isMandatory", "remove"];

    public groupName: FormControl;

    public isMandatory = false;

    public isSupervised = false;

    public selectedType: string;

    public groupTypes: string[] = [];

    public selectedItem: IListItem;

    public selectableList = new MatTableDataSource<IListItem>([]);

    @ViewChild("paginator", { static: true }) paginator: MatPaginator;
    @ViewChild("sort", { static: true }) sort: MatSort;

    @Input() course: CourseDto;

    constructor(public constants: Constants, public tooltipConst: TooltipConstants,
        private taskGroupService: TaskGroupService,
        private notifier: NotificationService, private dialogService: DialogService) {
        this.generateTypeOptions();

    }

    ngOnInit() {
        this.groupName = new FormControl("", [Validators.required]);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["course"] && this.course != null) {
            this.generateListItems();
            this.selectableList.sort = this.sort;
        }
    }

    public createGroup() {
        this.taskGroupService.create(this.course.id, {
            id: null, taskItems: null, groupType: this.selectedType,
            isMandatory: this.isMandatory, name: this.groupName.value, isSupervised: this.isSupervised
        }).subscribe(
            response => {
                if (response) {
                    const copy = Object.assign([], this.course.taskGroups);
                    copy.push(response);
                    this.course.taskGroups = copy;
                    this.selectedItem = null;
                    this.generateListItems();
                    this.initPanel();
                    this.notifier.showSuccess("Moment skapat");
                    this.selectItem(this.selectableList.data.find(i => i.payload.id === response.id));
                }
            });
    }

    public updateGroup() {
      debugger
        this.taskGroupService.update({
            id: this.selectedItem.payload.id,
            taskItems: null,
            groupType: this.selectedType,
            isMandatory: this.isMandatory,
            name: this.groupName.value,
            isSupervised: this.isSupervised
        }).subscribe(
            response => {
                if (response) {
                    const copy = Object.assign([], this.course.taskGroups);
                    const index = copy.indexOf(this.selectedItem);
                    if (index > -1) {
                        copy.splice(index, 1, response);
                    }
                    this.course.taskGroups = copy;
                    this.selectedItem.payload = response;
                    this.generateListItems();
                    this.initPanel();
                    this.notifier.showSuccess("Moment sparat");
                }
            });
    }

    public deleteGroup(group: TaskGroupDto, event) {
        event.stopPropagation();
        const dialogRef = this.dialogService.openDeleteConfirmationDialog(null);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.taskGroupService.delete(group.id).subscribe(
                    response => {
                        if (response) {
                            const copy = Object.assign([], this.course.taskGroups);
                            const index = copy.indexOf(group);
                            if (index > -1) {
                                copy.splice(index, 1);
                            }
                            this.course.taskGroups = copy;
                            if (this.selectedItem !== null && this.selectedItem.payload.id === group.id) this.selectedItem = null;
                            this.generateListItems();
                            this.initPanel();
                            this.notifier.showSuccess("Moment borttaget");
                        }
                    });
            }
        });
    }

    public selectItem(item: IListItem) {
        if (this.selectedItem && item === this.selectedItem) {
            item.highlighted = false;
            this.selectedItem = null;
        }
        else if (this.selectedItem && item !== this.selectedItem) {
            item.highlighted = true;
            this.selectedItem.highlighted = false;
            this.selectedItem = item;
        } else {
            item.highlighted = true;
            this.selectedItem = item;
        }
        this.initPanel();
    }

    public initPanel() {
        if (this.selectedItem == null) {
            this.selectedType = null;
            this.groupName = new FormControl("", [Validators.required]);
        } else {
            this.groupName = new FormControl(this.selectedItem.payload.name, [Validators.required]);
            this.selectedType = this.selectedItem.payload.groupType;
            this.isMandatory = this.selectedItem.payload.isMandatory;
            this.isSupervised = this.selectedItem.payload.isSupervised;
        }
    }

    public applyFilter(filterValue: string) {
        this.selectableList.filterPredicate =
            (data, filter: string): boolean => data.payload.name.toLowerCase().includes(filter);
        this.selectableList.filter = filterValue.trim().toLowerCase();

        if (this.selectableList.paginator) {
            this.selectableList.paginator.firstPage();
        }
    }

    private generateListItems(): void {
        if (!this.course) return;
        const listItems: IListItem[] = [];
        for (let group of this.course.taskGroups) {
            const item: IListItem = { highlighted: false, payload: group, name: group.name, groupType: group.id, isMandatory: group.isMandatory };
            if (this.selectedItem && this.selectedItem.payload === group) item.highlighted = true;
            listItems.push(item);
        }
        this.selectableList = new MatTableDataSource<IListItem>(listItems);
        this.selectableList.sort = this.sort;
        this.selectableList.paginator = this.paginator;
    }

    private generateTypeOptions() {
        this.groupTypes = [];
        for (let type in TaskType) {
            if (TaskType.hasOwnProperty(type)) {
                if (type.toString() === "Invalid") continue;
                this.groupTypes.push(type.toString());
            }
        }
    }
}
