   import { UserPreferenceDto } from './UserPreferenceDto';
import { CourseResultDto } from './CourseResultDto';

export class UserDto   {
	public id: string;
	public cn: string;
	public distinguishedName: string;
	public organization: string;
	public role: string;
	public firstName: string;
	public lastName: string;
	public email: string;
	public phoneNbr: string;
	public ssn: string;
	public isActive: boolean;
	public userPreference: UserPreferenceDto;
	public courseResults: CourseResultDto[];
}
