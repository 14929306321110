<mat-card class="mat-elevation-z">
  <mat-card-header>
    <div mat-card-avatar><mat-icon color="accent">people_outline</mat-icon></div>
    <mat-card-title>Användare</mat-card-title>
    <!--<mat-card-subtitle>{{currentUser.role}}</mat-card-subtitle>-->
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content style="padding: 10px">
    <mat-tab-group style="width: 100%">
      <mat-tab label="Aktiva">
        <div style="margin-left: 5px; margin-right: 5px; padding-bottom: 5px; margin-top: 10px" fxLayout="column" fxLayoutGap="15px">
          <user-search-panel (messageEvent)="receiveMessage($event)" style="margin-bottom: 10px"></user-search-panel>
          <mat-card>
            <mat-card-content>
              <div style="width: 100%; margin-bottom: 5px" fxLayout="row" fxLayoutGap="5px">
                <div fxLayout="column" style="width: 70%">

                </div>
                <mat-form-field fxLayout="column" style="width: 30%">
                  <input matInput (keyup)="applyActiveFilter($event.target.value)" placeholder="Filter">
                  <mat-icon matSuffix style="font-size: smaller" color="primary">filter_list</mat-icon>
                </mat-form-field>
              </div>
              <div class="example-container">
                <table mat-table #sortActive="matSort" [dataSource]="activeDataSource" style="width: 100%; margin-top: 0;" matSort>
                  <ng-container matColumnDef="role">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Roll</th>
                      <td mat-cell *matCellDef="let user" matTooltip="{{tooltipConst.getRole(user.role)}}"><mat-icon class="mat-table-icon">{{constants.getRoleIcon(user.role)}}</mat-icon></td>
                    </tr>
                  </ng-container>
                  <ng-container matColumnDef="ssn">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Personnummer</th>
                      <td mat-cell *matCellDef="let user">{{user.ssn}} </td>
                    </tr>
                  </ng-container>
                  <ng-container matColumnDef="firstName">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Förnamn</th>
                      <td mat-cell *matCellDef="let user">{{user.firstName}} </td>
                    </tr>
                  </ng-container>
                  <ng-container matColumnDef="lastName">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Efternamn</th>
                      <td mat-cell *matCellDef="let user">{{user.lastName}} </td>
                    </tr>
                  </ng-container>
                  <ng-container matColumnDef="email">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Email</th>
                      <td mat-cell *matCellDef="let user">{{user.email}} </td>
                    </tr>
                  </ng-container>
                  <ng-container matColumnDef="phoneNbr">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef disableClear>Telefon</th>
                      <td mat-cell *matCellDef="let user">{{user.phoneNbr}} </td>
                    </tr>
                  </ng-container>
                  <ng-container matColumnDef="remove">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef disableClear></th>
                      <td mat-cell *matCellDef="let user"><button mat-icon-button (click)="deleteUser(user)" matTooltip="Ta bort"><mat-icon color="primary">delete</mat-icon></button></td>
                    </tr>
                  </ng-container>
                  <ng-container matColumnDef="archive">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef disableClear></th>
                      <td mat-cell *matCellDef="let user"><button mat-icon-button (click)="archiveUser(user)" matTooltip="Arkivera"><mat-icon color="primary">archive</mat-icon></button></td>
                    </tr>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let user; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #paginatorActive [pageSizeOptions]="[10, 20, 50]" [length]="activeUsers.length" showFirstLastButtons></mat-paginator>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

      </mat-tab>
      <mat-tab label="Arkiverade">
        <div style="margin-left: 5px; margin-right: 5px; padding-bottom: 5px; margin-top: 10px" fxLayout="column" fxLayoutGap="15px">
          <mat-card>
            <mat-card-content>
              <div style="width: 100%; margin-bottom: 5px" fxLayout="row" fxLayoutGap="5px">
                <div fxLayout="column" style="width: 70%">

                </div>
                <mat-form-field fxLayout="column" style="width: 30%">
                  <input matInput (keyup)="applyInActiveFilter($event.target.value)" placeholder="Filter">
                  <mat-icon matSuffix style="font-size: smaller" color="primary">filter_list</mat-icon>
                </mat-form-field>
              </div>
              <div class="example-container">
                <table mat-table #sortInActive="matSort" [dataSource]="inactiveDataSource" style="width: 100%; margin-top: 0;" matSort>
                  <ng-container matColumnDef="role">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Roll</th>
                      <td mat-cell *matCellDef="let user" matTooltip="{{tooltipConst.getRole(user.role)}}"><mat-icon class="mat-table-icon">{{constants.getRoleIcon(user.role)}}</mat-icon></td>
                    </tr>
                  </ng-container>
                  <ng-container matColumnDef="ssn">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Personnummer</th>
                      <td mat-cell *matCellDef="let user">{{user.ssn}} </td>
                    </tr>
                  </ng-container>
                  <ng-container matColumnDef="firstName">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Förnamn</th>
                      <td mat-cell *matCellDef="let user">{{user.firstName}} </td>
                    </tr>
                  </ng-container>
                  <ng-container matColumnDef="lastName">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef mat-sort-header>Efternamn</th>
                      <td mat-cell *matCellDef="let user">{{user.lastName}} </td>
                    </tr>
                  </ng-container>
                  <ng-container matColumnDef="email">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Email</th>
                      <td mat-cell *matCellDef="let user">{{user.email}} </td>
                    </tr>
                  </ng-container>
                  <ng-container matColumnDef="phoneNbr">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef disableClear>Telefon</th>
                      <td mat-cell *matCellDef="let user">{{user.phoneNbr}} </td>
                    </tr>
                  </ng-container>
                  <ng-container matColumnDef="remove">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef disableClear></th>
                      <td mat-cell *matCellDef="let user"><button mat-icon-button (click)="deleteUser(user)" matTooltip="Ta bort"><mat-icon color="primary">delete</mat-icon></button></td>
                    </tr>
                  </ng-container>
                  <ng-container matColumnDef="archive">
                    <tr>
                      <th mat-header-cell *matHeaderCellDef disableClear></th>
                      <td mat-cell *matCellDef="let user"><button mat-icon-button (click)="archiveUser(user)" matTooltip="Återställ"><mat-icon color="primary">archive</mat-icon></button></td>
                    </tr>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let user; columns: displayedColumns;"></tr>
                </table>
                <mat-paginator #paginatorInactive [pageSizeOptions]="[10, 20, 50]" [length]="archivedUsers.length" showFirstLastButtons></mat-paginator>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>

    </mat-tab-group>

  </mat-card-content>
</mat-card>



