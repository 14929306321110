import { BaseService } from "./base.service";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserPreferenceDto } from "../models/UserPreferenceDto";
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserPreferenceService extends BaseService {

  constructor(http: HttpClient) {
    super(http);

    this.controllerUrl = '/userpreference';
  }

  public create(userId: string, created: UserPreferenceDto): Observable<UserPreferenceDto> {
    return this.http.post<UserPreferenceDto>(this.apiUrl + this.controllerUrl + "/create/?userId=" + userId, created, { withCredentials: true });
  }

  public update(updated: UserPreferenceDto): Observable<UserPreferenceDto> {
    return this.http.put<UserPreferenceDto>(this.apiUrl + this.controllerUrl + "/update", updated, { withCredentials: true });
  }
}
