<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px" fxFill>
  <div fxFlex="70">
    <h2 mat-dialog-title *ngIf="!data.created">Skapa en ny kurs</h2>
    <h2 mat-dialog-title *ngIf="data.created">{{courseTitle.value}}</h2>
  </div>
  <div fxFlex="30" fxLayout="column" fxLayoutAlign="start end" fxFlexAlign="start">
    <mat-slide-toggle [formControl]="courseState" color="accent" (change)="onStateChange($event)" [checked]="getCourseState()" disableRipple="true" labelPosition="before"><mat-label style="font-size: smaller">{{state}}</mat-label></mat-slide-toggle>
  </div>
</div>
<mat-dialog-content fxLayout="column" fxLayoutAlign="space-around start">
  <div>
    <form>
     
      <div>
        <mat-form-field style="width: 100%">
          <input matInput name="title" placeholder="Kurstitel" required [formControl]="courseTitle" style="width: 100%">
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 45%">
          <input matInput [matDatepicker]="picker1" [matDatepickerFilter]="startDateFilter" placeholder="startdatum" required [formControl]="startDate">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="width: 45%; margin-left: 10%">
          <input matInput [matDatepicker]="picker2" [matDatepickerFilter]="endDateFilter" placeholder="slutdatum" required [formControl]="endDate">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="width: 100%" *ngIf="!data.created">
          <mat-label>Skapa en tom eller kopiera en befintlig kurs</mat-label>
          <mat-select [(ngModel)]="selectedCopy" name="copySelector">
            <mat-option *ngFor="let course of selectableCourses" [value]="course">
              {{course.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close>Avbryt</button>
  <button mat-button color="accent" [disabled]="!courseTitle.valid || !startDate.valid || !endDate.valid" *ngIf="!data.created" (click)="createCourse()">Skapa</button>
  <button mat-button color="accent" [disabled]="!courseTitle.valid || !startDate.valid || !endDate.valid" *ngIf="data.created" (click)="updateCourse()">Spara</button>
</mat-dialog-actions>
