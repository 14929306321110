import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AccountService } from "../../services/account.service";
import { UserDto } from "../../models/UserDto";
import { Role } from "../../models/enum";

@Component({
  selector: 'user-search-panel',
  templateUrl: './user-search-panel.component.html',
  styleUrls: ['./user-search-panel.component.css']
})
export class UserSearchPanelComponent implements OnInit {

  constructor(private accountService: AccountService) { }

  public foundUsers: UserDto[] = [];

  public availableRoles = [Role.Administrator, Role.Student, Role.Teacher, Role.Supervisor];

  public selectedUsers: UserDto[] = [];

  @Output() messageEvent = new EventEmitter<UserDto>();

  ngOnInit() {
  }

    search(query: string): void {
    this.accountService.findUser(query).subscribe(result => {
      if (result != null) {
        this.foundUsers = result;
      }
    });
  }

  registerUser(role: string) {
    for (let user of this.selectedUsers) {
      user.role = role;
      this.accountService.registerUser(user).subscribe(result => {
        if (result != null) {
          this.selectedUsers = [];
          this.messageEvent.emit(result);
        }
      });
    }
  }
}
