<mat-card class="mat-elevation-z" *ngIf="course">
  <mat-card-header>
    <div mat-card-avatar><mat-icon color="accent">school</mat-icon></div>
    <mat-card-title>
      {{course.name}}
    </mat-card-title>
    <mat-card-subtitle>
      {{course.startDate | date : "yyyy-MM-dd"}} - {{course.endDate | date : "yyyy-MM-dd"}}
    </mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content style="padding-top: 10px; padding-bottom: 10px">
    <mat-accordion>
      <progress-details *ngFor="let item of resultItems" [item]="item" [currentUser]="currentUser" (refreshResult)="onRefreshResult($event)"></progress-details>
    </mat-accordion>
  </mat-card-content>
</mat-card>

