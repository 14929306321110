<mat-card *ngIf="currentUser" class="mat-elevation-z">
  <mat-card-header>
    <div mat-card-avatar><mat-icon color="accent">{{constants.getRoleIcon(currentUser.role)}}</mat-icon></div>
    <mat-card-title>{{currentUser.firstName}} {{currentUser.lastName}}</mat-card-title>
    <mat-card-subtitle>{{currentUser.role}}</mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content style="padding: 10px">
    <div fxLayout="column" fxLayoutAlign="space-around center">
      <form [formGroup]="formGroup">
        <div fxLayout="column" fxLayoutAlign="space-around start" fxLayoutGap="10px">
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <mat-icon color="primary">alternate_email</mat-icon><mat-label style="font-size: 18px; font-weight: bold">{{currentUser.email}}</mat-label>
          </div>
          <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <mat-icon color="primary">phone</mat-icon><mat-label style="font-size: 18px; font-weight: bold">{{currentUser.phoneNbr}}</mat-label>
          </div>
          <!--Removed by owner 2019.10.17-->
          <!--<div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
            <mat-icon color="primary">perm_identity</mat-icon><mat-label style="font-size: 18px; font-weight: bold">{{currentUser.ssn}}</mat-label>
          </div>-->
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <mat-icon color="primary">work_outline</mat-icon>
            <mat-form-field>
              <input matInput name="organization" formControlName="organization" style="font-size: 18px; font-weight: bold">
              <mat-icon matSuffix style="font-size: medium" color="accent">edit</mat-icon>
              <mat-hint>din organisation</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-button color="primary" (click)="onSave()" [disabled]="!formGroup.dirty">Spara</button>
  </mat-card-actions>
</mat-card>
