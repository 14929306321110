<mat-expansion-panel class="mat-elevation-z" fxFill hideToggle="true" [expanded]="true">
  <mat-expansion-panel-header [collapsedHeight]="'50px'" [expandedHeight]="'50px'">
    <mat-panel-title style="height: 50px" fxLayoutAlign="start center">
      Kommentarer
    </mat-panel-title>
    <mat-panel-description style="height: 50px" fxLayoutAlign="start center" fxHide fxShow.gt-sm>
      Läs och lägg till kommentarer
    </mat-panel-description>
    <div fxLayout="row" fxLayoutAlign="end center" style="height: 50px; margin-right: 10px">
      <mat-icon [matBadge]="getUnread()" matBadgeColor="accent" matBadgePosition="above after" matBadgeOverlap="true" matBadgeSize="small" color="primary" *ngIf="getUnread() > 0">flag</mat-icon>
      <mat-icon color="primary" *ngIf="getUnread() == 0">outlined_flag</mat-icon>
    </div>
  </mat-expansion-panel-header>
  <mat-card *ngIf="!isReadOnly()">
    <mat-card-content fxLayout="row" fxLayoutAlign="space-between center" fxFill fxLayoutGap="10px" style="padding: 5px">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" fxFlex="80">
        <form [formGroup]="commentForm" fxFill>
          <div fxLayout="row" fxLayoutAlign="space-between center" fxFill>
            <div fxLayout="column" fxLayoutAlign="space-around center" fxFlex="15">
              <mat-label style="font-size: x-small;">vecka</mat-label>
              <mat-label color="primary" style="font-weight: bold">{{currentWeek}}</mat-label>
            </div>
            <mat-form-field fxFlex="85">
              <textarea matInput #input placeholder="Lägg till kommentar" formControlName="comment" maxlength="1500" fxFill required></textarea>
              <mat-hint align="end">{{input.value?.length || 0}}/1500</mat-hint>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div fxFlex="20" fxLayout="row" fxLayoutAlign="space-around center">
        <button mat-icon-button color="primary" [disabled]="!commentForm.dirty" (click)="onCancel()" matTooltip="Ångra"><mat-icon>clear</mat-icon></button>
        <button mat-icon-button color="accent" [disabled]="!commentForm.dirty || !commentForm.valid" (click)="onSubmit()" matTooltip="Lägg till"><mat-icon>create</mat-icon></button>
      </div>
    </mat-card-content>
  </mat-card>
  <div fxLayout="row" fxLayoutAlign="end center">
    <mat-icon color="accent" style="margin-top: 10px" matTooltip="Klicka på en kommentar för att markera den som läst. Du kan endast läsa kommentarer som är avsedda för dig. Flaggorna markerar lästa/olästa kommentarer">info</mat-icon>
  </div>
  <mat-divider style="margin-top:10px; margin-bottom:10px"></mat-divider>
  <table mat-table [dataSource]="commentsDataSource" style="width: 100%; overflow: auto; margin-top: 10px ">
    <ng-container matColumnDef="week">
      <tr>
        <th mat-header-cell *matHeaderCellDef ><mat-label fxHide fxShow.gt-md>Vecka</mat-label></th>
        <td mat-cell *matCellDef="let comment" style="width: 10%">
          <div fxLayoutAlign="start center">
            <div fxLayout="column" fxLayoutAlign="center center">
              <mat-label style="font-size: x-small;">vecka</mat-label>
              <mat-label style="font-size: small; font-weight: bold">{{getWeekNbr(comment.date)}}</mat-label>
            </div>
          </div>
        </td>
      </tr>
    </ng-container>
    <ng-container matColumnDef="read">
      <tr>
        <th mat-header-cell *matHeaderCellDef ><mat-label fxHide fxShow.gt-md>Läst/Oläst</mat-label></th>
        <td mat-cell *matCellDef="let comment" style="width: 10%">
          <div fxLayoutAlign="start center">
            <mat-icon matListIcon *ngIf="!comment.isRead" color="primary">flag</mat-icon>
            <mat-icon matListIcon *ngIf="comment.isRead" color="primary">outlined_flag</mat-icon>
          </div>
        </td>
      </tr>
    </ng-container>
    <ng-container matColumnDef="content">
      <tr>
        <th mat-header-cell *matHeaderCellDef>Kommentar</th>
        <td mat-cell *matCellDef="let comment" style="width: 65%; height: auto; white-space: pre-wrap; word-break: break-all">
          <mat-label>{{comment.text}}</mat-label>

          <!--<div fxLayout="column">
            <mat-label style="word-wrap: normal">{{comment.text}}</mat-label>
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="30">
              <mat-icon style="color: gray; transform: scale(0.75);">{{constants.getRoleIcon(comment.leftBy.role)}}</mat-icon>
              <mat-label style="color: gray; font-size: x-small; font-weight: bold">{{comment.leftBy.firstName}} {{comment.leftBy.lastName}}</mat-label>
            </div>
          </div>-->
        </td>
      </tr>
    </ng-container>
    <ng-container matColumnDef="leftBy">
      <tr>
        <th mat-header-cell *matHeaderCellDef>Från</th>
        <td mat-cell *matCellDef="let comment" style="width: 10%">
          <mat-icon color="primary" matTooltip="{{comment.leftBy.firstName}} {{comment.leftBy.lastName}}">{{constants.getRoleIcon(comment.leftBy.role)}}</mat-icon>
        </td>
      </tr>
    </ng-container>
    <ng-container matColumnDef="delete">
      <tr>
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let comment" style="width: 5%">
          <button mat-icon-button color="warn" (click)="onDelete(comment, $event)" [disabled]="currentUser.id != comment.leftBy.id && currentUser.role != 'Administrator' || isReadOnly()"><mat-icon style="font-size: medium">clear</mat-icon></button>
        </td>
      </tr>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let comment; columns: displayedColumns;" (click)="readComment(comment)"></tr>
  </table>
  <mat-paginator #paginator [pageSizeOptions]="[10, 20, 50]" [length]="currentComments.length" showFirstLastButtons></mat-paginator>
</mat-expansion-panel>
