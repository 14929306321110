import { Component, OnInit, Input, SimpleChanges, ViewChild } from '@angular/core';
import { UserDto } from "../../models/UserDto";
import { Role } from "../../models/enum";
import { CourseResultService } from "../../services/course-result.service";
import { NotificationService } from "../../common/notification.service";
import { MatSort } from "@angular/material/sort";
import { MatPaginator}from "@angular/material/paginator";
import { MatTableDataSource} from "@angular/material/table";
import { Constants } from "../../models/constants";
import { SelectionModel } from '@angular/cdk/collections';
import Administrator = Role.Administrator;
import { TooltipConstants } from "../../common/tooltip.constants";

export interface IListItem<T> {
    highlighted?: boolean;
    role: string;
    firstName: string;
    lastName: string;
    ssn: string;
    payload: T
}

@Component({
    selector: 'course-users',
    templateUrl: './course-users.component.html',
    styleUrls: ['./course-users.component.css']
})
export class CourseUsersComponent implements OnInit {

    public displayedColumns: string[] = ["ssn", "firstName", "lastName", "role"];

    public selectedAssigned: UserDto[] = [];

    public selectedUnassigned: UserDto[] = [];

    public unassignedDataSource = new MatTableDataSource([]);

    public assignedDataSource = new MatTableDataSource([]);

    public selectableUnassigned: IListItem<UserDto>[] = [];

    public selectableAssigned: IListItem<UserDto>[] = [];

    @Input() users: UserDto[] = [];
    @Input() courseId: string;

    @ViewChild("paginatorUnassigned", { static: true }) paginatorUnassigned: MatPaginator;
    @ViewChild("paginatorAssigned", { static: true }) paginatorAssigned: MatPaginator;

    @ViewChild("sortAssigned", { static: true }) sortAssigned: MatSort;
    @ViewChild("sortUnassigned", { static: true }) sortUnassigned: MatSort;

    constructor(private courseResultService: CourseResultService, private notificationService: NotificationService, public constants: Constants, public tooltipConst: TooltipConstants) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes["users"] && this.users != null && changes["courseId"] && this.courseId != null) {
            this.buildDataSources();
        }
    }

    public register() {
        const selectedIds = this.selectedUnassigned.map(({ id }) => id);
        this.courseResultService.createUserResult(this.courseId, selectedIds).subscribe(result => {
            if (result != null) {
                for (let course of result) {
                    const user = this.users.find(u => u.id === course.user.id);
                    user.courseResults.push(course);
                }
                this.selectedUnassigned = [];
                if (result.length === selectedIds.length) this.notificationService.showSuccess(result.length + " deltagare registrerad(e)");
                else this.notificationService.showError("Kan inte registrera alla deltagare.");
                this.buildDataSources();
            }
        });
    }

    public unRegister() {
        const remove: string[] = [];
        for (let user of this.selectedAssigned) {
            const result = user.courseResults.filter(r => r.course.id === this.courseId).map(r => r.id);
            Array.prototype.push.apply(remove, result);
        }
        this.courseResultService.deleteUserResult(remove).subscribe(results => {
            if (results) {
                for (let result of results) {
                    for (let user of this.selectedAssigned) {
                        const removed = user.courseResults.find(r => r.id === result);
                        if (removed) {
                            const index = user.courseResults.indexOf(removed, 0);
                            if (index > -1) {
                                user.courseResults.splice(index, 1);
                            }
                            const i = this.selectedAssigned.indexOf(user, 0);
                            if (i > -1) {
                                this.selectedAssigned.splice(i, 1);
                            }
                        }
                    }
                }
                if (remove.length === results.length) this.notificationService.showSuccess(results.length + " deltagare avregistrerad(e)");
                else this.notificationService.showError("Kan inte avregistrera alla deltagare.");
                this.buildDataSources();
            }
        });
    }

    public applyUnassignedFilter(filterValue: string) {
        this.unassignedDataSource.filter = filterValue.trim().toLowerCase();

        if (this.unassignedDataSource.paginator) {
            this.unassignedDataSource.paginator.firstPage();
        }
    }

    public applyAssignedFilter(filterValue: string) {
        this.assignedDataSource.filter = filterValue.trim().toLowerCase();

        if (this.assignedDataSource.paginator) {
            this.assignedDataSource.paginator.firstPage();
        }
    }

    public selectUnassigned(item: IListItem<UserDto>) {
        item.highlighted = !item.highlighted;
        if (item.highlighted) this.selectedUnassigned.push(item.payload);
        else {
            const index = this.selectedUnassigned.indexOf(item.payload);
            if (index > -1) {
                this.selectedUnassigned.splice(index, 1);
            }
        }
    }

    public selectAssigned(item: IListItem<UserDto>) {
        item.highlighted = !item.highlighted;
        if (item.highlighted) this.selectedAssigned.push(item.payload);
        else {
            const index = this.selectedAssigned.indexOf(item.payload);
            if (index > -1) {
                this.selectedAssigned.splice(index, 1);
            }
        }
    }

    private buildDataSources() {
        this.sortUsers();
        this.assignedDataSource = new MatTableDataSource<IListItem<UserDto>>(this.selectableAssigned);
        this.assignedDataSource.paginator = this.paginatorAssigned;
        this.assignedDataSource.sort = this.sortAssigned;

        this.unassignedDataSource = new MatTableDataSource<IListItem<UserDto>>(this.selectableUnassigned);
        this.unassignedDataSource.paginator = this.paginatorUnassigned;
        this.unassignedDataSource.sort = this.sortUnassigned;
    }

    private sortUsers() {
        this.selectableAssigned = [];
        this.selectableUnassigned = [];
        for (let user of this.users) {
            let selected = this.selectedAssigned.find(u => u.id === user.id) != null || this.selectedUnassigned.find(u => u.id === user.id) != null;

            const selectable = {
                highlighted: selected,
                firstName: user.firstName,
                lastName: user.lastName,
                role: user.role,
                payload: user,
                ssn: user.ssn
            };
            if (user.role === Administrator.toString()) continue;
            user.courseResults.find(cr => cr.course.id === this.courseId)
                ? this.selectableAssigned.push(selectable)
                : this.selectableUnassigned.push(selectable);

        }
    }

}
