import { Routes, RouterModule } from '@angular/router';
import { FieldCourseComponent } from './field-course-result/field-course-result.component';
import { UserManagerComponent } from "./user-manager/user-manager.component";
import { CourseManagerComponent } from "./course-manager/course-manager.component";
import { StudentProgressComponent } from "./student-progress/student-progress.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { LoginComponent } from './login/login.component';

const appRoutes: Routes = [
    { path: 'user-manager', component: UserManagerComponent },
    { path: 'field-course-result', component: FieldCourseComponent },
    { path: 'course-manager', component: CourseManagerComponent },
    { path: 'student-progress', component: StudentProgressComponent },
    { path: 'user-profile', component: UserProfileComponent },
    { path: 'login', component: LoginComponent },

  // otherwise redirect to home
  //TODO add error page Not found or....
    { path: '**', redirectTo: 'login' }
];
//index.html
export const routing = RouterModule.forRoot(appRoutes);
