import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { IResultItem, IWeekItem } from "../student-progress.component";
import { UserDto } from "../../models/UserDto";
import { Constants } from "../../models/constants";
import { IDuration, HelperService } from "../../common/helper.service";
import { ResultState, CourseResultState, TaskType } from "../../models/enum";
import Absence = TaskType.Absence;
import { TooltipConstants } from "../../common/tooltip.constants";

@Component({
    selector: 'progress-details',
    templateUrl: './progress-details.component.html',
    styleUrls: ['./progress-details.component.css']
})
export class ProgressDetailsComponent {

    public weeks: IWeekItem[] = [];

    public selectedWeekItem: IWeekItem;

    public selectedDuration: IDuration;

    public panelOpen: boolean = false;

    @Input() currentUser: UserDto;

    @Input() item: IResultItem;

    @Output() refreshResult = new EventEmitter<any>();

    constructor(public constants: Constants, public tooltipConst: TooltipConstants) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["item"] && this.item) {
            this.initWeeks();
        }
    }

    public isInState(state: string): boolean {
        return this.item.result.state === state;
    }

    public onRefreshResult(event: any) {
        this.refreshResult.emit(event);
        this.initWeeks();
    }

    public onWeekSelected(selectedWeek: IWeekItem, event: any): void {
        if (event && this.panelOpen)
            event.stopPropagation();

        if (this.selectedWeekItem != null) {
            this.selectedWeekItem.highlighted = false;
            this.selectedDuration = null;
        }
        selectedWeek.highlighted = true;
        this.selectedWeekItem = selectedWeek;
        this.selectedDuration = selectedWeek.duration;
    }

    private initWeeks(): void {
        if (this.item.result === null) return;
        const weeks: { [id: string]: IWeekItem; } = {};

        for (let tgr of this.item.result.taskGroupResults) {

            const duration = HelperService.getWeekDuration(new Date(tgr.startDate));
            let current = duration.start;
            do {
                const week = HelperService.ISO8601_week_no(new Date(current));
                let key = new Date(current).getFullYear().toString() + week.toString();
                if (!(key in weeks)) {
                    weeks[key] = {
                        duration: HelperService.getWeekDuration(new Date(current)), filedCounter: 0, highlighted: false, notAttendedCounter: 0, nbr: week, rejectedCounter: 0, signedCounter: 0, startedCounter: 0
                    }
                }
                if (tgr.taskGroup.groupType === Absence.toString()) {
                    const weekEndDate = new Date(weeks[key].duration.end.getTime() - 2 * (1000 * 60 * 60 * 24));
                    const tgrEnd = new Date(tgr.endDate).setHours(23, 59, 0, 0);
                    const min = Math.min(
                        (weekEndDate.getTime() - weeks[key].duration.start.getTime()) / (1000 * 60 * 60 * 24),
                        (weekEndDate.getTime() - new Date(tgr.startDate).getTime()) / (1000 * 60 * 60 * 24),
                        (new Date(tgrEnd).getTime() - new Date(tgr.startDate).getTime()) / (1000 * 60 * 60 * 24),
                        (new Date(tgrEnd).getTime() - weeks[key].duration.start.getTime()) / (1000 * 60 * 60 * 24));

                    weeks[key].notAttendedCounter += Math.ceil(min);
                } else {
                    switch (tgr.state) {
                        case ResultState.Started.toString():
                            {
                                weeks[key].startedCounter++;
                                break;
                            }
                        case ResultState.Filed.toString():
                            {
                                weeks[key].filedCounter++;
                                break;
                            }
                        case ResultState.Rejected.toString():
                            {
                                weeks[key].rejectedCounter++;
                                break;
                            }
                        case ResultState.Signed.toString():
                            {
                                weeks[key].signedCounter++;
                                break;
                            }
                        default:
                            break;
                    }
                }
                current.setDate(current.getDate() + 7);

            } while (current.getTime() <= new Date(tgr.endDate).getTime())
        }
        this.weeks = [];
        for (let key in weeks) {
            if (weeks.hasOwnProperty(key)) {
                const value = weeks[key];
                if (this.selectedWeekItem && value.nbr === this.selectedWeekItem.nbr) {
                    this.onWeekSelected(value, null);
                }
                this.weeks.push(value);
            }
        }
        this.weeks.sort((a, b) => (a.duration.start.getTime() > b.duration.start.getTime()) ? 1 : -1);
    }
}
