import { Component, OnInit } from '@angular/core';
import { StudentProgressDataService, IStudentProgressMessage } from "./student-progress-data.service";
import { UserDto } from "../models/UserDto";
import { CourseDto } from "../models/CourseDto";
import { CourseResultDto } from "../models/CourseResultDto";
import { CourseResultService } from "../services/course-result.service";
import { ResultState, TaskType } from "../models/enum";
import { IDuration } from "../common/helper.service";
import { Constants } from "../models/constants";
import Absence = TaskType.Absence;

export interface IResultItem {
  result: CourseResultDto,
  filedCounter: number,
  startedCounter: number,
  rejectedCounter: number,
  signedCounter: number,
  notAttendedCounter: number,
}

export interface IWeekItem {
  nbr: number,
  duration: IDuration,
  filedCounter: number,
  startedCounter: number,
  rejectedCounter: number,
  signedCounter: number,
  notAttendedCounter: number,
  highlighted?: boolean,
}

@Component({
  selector: 'student-progress',
  templateUrl: './student-progress.component.html',
  styleUrls: ['./student-progress.component.css']
})
export class StudentProgressComponent implements OnInit {

  public currentUser: UserDto;

  public course: CourseDto;

  public results: CourseResultDto[] = [];

  public resultItems: IResultItem[] = [];

  constructor(private dataService: StudentProgressDataService,
    private courseResultService: CourseResultService,
    private constants: Constants) {
    this.dataService.currentMessage.subscribe(message => {
      if (message != null) {
        if (message as IStudentProgressMessage) {
          this.currentUser = (message as IStudentProgressMessage).currentUser;
          this.course = (message as IStudentProgressMessage).course;
          if (this.course !== null) {
            this.courseResultService.getUserResultsByCourseId(this.course.id).subscribe(result => {
              if (result) {
                this.results = result;
                this.initResults();
              }
            });
          }
        }
      }
    });
  }

  ngOnInit() {

  }

  public onRefreshResult(event: any) {
    this.recalculate();
  }

  private initResults(): void {
    this.resultItems = [];
    if (!this.results) return;
    for (let result of this.results) {
      const item: IResultItem = { filedCounter: 0, notAttendedCounter: 0, rejectedCounter: 0, result: result, signedCounter: 0, startedCounter: 0 };
      for (let tgr of result.taskGroupResults) {
        if (tgr.taskGroup.groupType === Absence.toString()) {
          item.notAttendedCounter += this.getAbsence(tgr.startDate, tgr.endDate);
          continue;
        }
        switch (tgr.state) {
          case ResultState.Started.toString():
            {
              item.startedCounter++;
              break;
            }
          case ResultState.Filed.toString():
            {
              item.filedCounter++;
              break;
            }
          case ResultState.Rejected.toString():
            {
              item.rejectedCounter++;
              break;
            }
          case ResultState.Signed.toString():
            {
              item.signedCounter++;
              break;
            }
          default:
            break;
        }

      }
      this.resultItems.push(item);
    }
  }

  private recalculate() {
    for (let item of this.resultItems) {
      item.filedCounter = 0;
      item.notAttendedCounter = 0;
      item.rejectedCounter = 0;
      item.signedCounter = 0;
      item.startedCounter = 0;
      for (let tgr of item.result.taskGroupResults) {
        if (tgr.taskGroup.groupType === Absence.toString()) {
          item.notAttendedCounter += this.getAbsence(tgr.startDate, tgr.endDate);
          continue;
        }
        switch (tgr.state) {
          case ResultState.Started.toString():
            {
              item.startedCounter++;
              break;
            }
          case ResultState.Filed.toString():
            {
              item.filedCounter++;
              break;
            }
          case ResultState.Rejected.toString():
            {
              item.rejectedCounter++;
              break;
            }
          case ResultState.Signed.toString():
            {
              item.signedCounter++;
              break;
            }
          default:
            break;
        }

      }
    }
  }

  private getAbsence(start: Date, end: Date): number {
    let counter = 0;
    let current = new Date(start);
    let last = new Date(end);
    last.setHours(23, 59, 0, 0);
    while (current.getTime() <= last.getTime()) {
      if (current.getDay() < 6 && current.getDay() > 0)
        counter++;

      current = new Date(current.getTime() + (1000 * 60 * 60 * 24));
    }
    return counter;
  }
}
