import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { TaskGroupDto } from "../../models/TaskGroupDto";
import { Constants } from "../../models/constants";
import { TaskItemDto } from "../../models/TaskItemDto";
import { TaskItemType } from "../../models/enum";
import { FormControl, Validators } from "@angular/forms";
import { TaskItemService } from "../../services/task-item.service";
import { NotificationService } from "../../common/notification.service";
import { DialogService } from "../../common/dialog.service";
import { TooltipConstants } from '../../common/tooltip.constants';

export interface IListItem<T> {
  highlighted?: boolean;
  payload: T;
}

@Component({
  selector: 'task-group-details',
  templateUrl: './task-group-details.component.html',
  styleUrls: ['./task-group-details.component.css']
})
export class TaskGroupDetailsComponent implements OnInit {

  public selectableList: IListItem<TaskItemDto>[] = [];

  public selectedItem: TaskItemDto;

  public taskItemTypes: string[] = [];

  public selectedType: string = null;

  public taskQuery: FormControl = new FormControl("", [Validators.required]);

  @Input() taskGroup: TaskGroupDto;
  @Input() courseTitle: TaskGroupDto;


    constructor(public constants: Constants, private service: TaskItemService, private notifier: NotificationService, private dialogService: DialogService, public tooltipConst:TooltipConstants) {
  }

  ngOnInit() {
    this.generateTypeOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["taskGroup"] && this.taskGroup != null) {
      this.generateListItems();
    }
  }

  public createTask(): void {
    this.service
      .create(this.taskGroup.id, { id: null, taskType: this.selectedType, name: "", query: this.taskQuery.value, })
      .subscribe(result => {
        if (result) {
          this.taskGroup.taskItems.push(result);
          this.generateListItems();
          this.notifier.showSuccess("En ny fråga skapad");
        }
      });
  }

  public updateTask(): void {
    //this.selectedItem.query = this.taskQuery.value;
    //this.selectedItem.taskType = this.selectedType;
      debugger
    this.service.update({ id: this.selectedItem.id, query: this.taskQuery.value, taskType: this.selectedType, name: "" }).subscribe(result => {
      if (result) {
        let index = this.taskGroup.taskItems.findIndex(i => i.id === result.id);
        if (index !== -1) {
          this.taskGroup.taskItems.splice(index, 1, result);
          this.generateListItems();
          this.notifier.showSuccess("Uppdaterad");
        }
      }
    });
  }

  public deleteItem(item: TaskItemDto, event) {
    event.stopPropagation();
    const dialogRef = this.dialogService.openDeleteConfirmationDialog(null);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.delete(item.id).subscribe(result => {
          if (result) {
            let index = this.taskGroup.taskItems.indexOf(item);
            if (index !== -1) {
              this.taskGroup.taskItems.splice(index, 1);
              this.generateListItems();
              this.notifier.showSuccess("Borttagen");
            }
          }
        });
      }
    });
  }

  public resetInput(): void {
    if (this.selectedItem == null) {
      this.selectedType = null;
      this.taskQuery = new FormControl("", [Validators.required]);
    } else {
      this.selectedType = this.selectedItem.taskType;
      this.taskQuery = new FormControl(this.selectedItem.query, [Validators.required]);
    }
  }

  public selectItem(item: IListItem<TaskItemDto>) {
    item.highlighted = !item.highlighted;

    var unselect = this.selectableList.find(i => i.highlighted && i !== item);
    if (unselect != null) unselect.highlighted = false;

    if (item.highlighted)
      this.selectedItem = item.payload;
    else
      this.selectedItem = null;

    if (this.selectedItem != null) {
      this.taskQuery = new FormControl(this.selectedItem.query, [Validators.required]);
      this.selectedType = this.selectedItem.taskType;
    } else {
      this.taskQuery = new FormControl("", [Validators.required]);
      this.selectedType = null;
    }
  }

  private generateListItems(): void {
    if (this.taskGroup == null) return;
    this.selectableList = [];
    this.selectedItem = null;
    this.resetInput();
    for (let taskItem of this.taskGroup.taskItems) {
      var item: IListItem<TaskItemDto> = { highlighted: false, payload: taskItem };
      if (this.selectedItem !== null && this.selectedItem === taskItem) item.highlighted = true;
      this.selectableList.push(item);
    }
  }

  private generateTypeOptions() {
    this.taskItemTypes = [];
    for (let type in TaskItemType) {
      if (TaskItemType.hasOwnProperty(type)) {
        if (type.toString() === "Invalid") continue;
        this.taskItemTypes.push(type.toString());
      }
    }
  }
}
