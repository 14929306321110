import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class BaseService {
  protected apiUrl: string;
  protected controllerUrl: string;

  constructor(protected http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

}
