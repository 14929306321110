<mat-card class="mat-elevation-z">
  <mat-card-header>
    <div mat-card-avatar><mat-icon color="accent">folder_open</mat-icon></div>
    <mat-card-title>Kurser</mat-card-title>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content style="padding: 10px">
    <mat-tab-group>
      <mat-tab label="Aktiva" >
        <active-courses [activeCourses]="activeCourses" [users]="users" [currentUser]="currentUser" [courses]="courses" (refreshTrigger)="refreshEvent($event)"></active-courses>
      </mat-tab>
      <mat-tab label="Inaktiva" >
        <inactive-courses [inactiveCourses]="inactiveCourses" [courses]="courses" (refreshTrigger)="refreshEvent($event)"></inactive-courses>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>



