   import { TaskItemDto } from './TaskItemDto';

export class TaskGroupDto   {
	public id: string;
	public name: string;
	public groupType: string;
	public isMandatory: boolean;
	public isSupervised: boolean;
	public taskItems: TaskItemDto[];
}
