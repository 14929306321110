import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { CourseDto } from "../../models/CourseDto";
import { CourseService } from "../../services/course.service";
import { NotificationService } from "../../common/notification.service";
import { CourseState } from "../../models/enum";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import Active = CourseState.Active;

export interface IAddNewCourse {
    created: CourseDto;
    courses: CourseDto[];
}

@Component({
    selector: 'add-new-course-dialog',
    templateUrl: './add-new-course-dialog.component.html',
    styleUrls: ['./add-new-course-dialog.component.css']
})
export class AddNewCourseDialogComponent implements OnInit {

    public selectedCopy: CourseDto;

    public courseForm:FormGroup;

    public courseTitle: FormControl;

    public startDate: FormControl;

    public endDate: FormControl;

    public courseState: FormControl;

    public selectableCourses: CourseDto[] = [];

    public state: string;

    constructor(private service: CourseService, public dialogRef: MatDialogRef<AddNewCourseDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IAddNewCourse, fb: FormBuilder, private notifier: NotificationService) {

    }

    ngOnInit() {
        this.data.created == null ? this.initCreateState() : this.initUpdateState();
    }

    public onStateChange(event: MatSlideToggleChange) {
      if (event.checked)
        this.state = "Aktiv";
      else
        this.state = "Inaktiv";
    }

    public getCourseState(): boolean {
        return this.state === CourseState.Active.toString();
    }

    public startDateFilter = (d: Date): boolean => {
        if (this.endDate.value !== "" && d.getTime() > this.endDate.value.getTime()) return false;
        const day = d.getDay();
        // Prevent Saturday and Sunday from being selected.
        return day !== 0 && day !== 6;
    }

    public endDateFilter = (d: Date): boolean => {
        if (this.startDate.value !== "" && d.getTime() < this.startDate.value.getTime()) return false;
        const day = d.getDay();
        // Prevent Saturday and Sunday from being selected.
        return day !== 0 && day !== 6;
    }

    public createCourse() {
        // solve with validator
        if (this.data.courses.filter(c => c.name === this.courseTitle.value).length > 0) {
            this.notifier.showError("Titeln används redan");
            return;
        }

        const copyId = this.selectedCopy !== this.selectableCourses[0] ? this.selectedCopy.id : "";

        this.service.create(copyId,
            {
                state: this.getState(),
                taskGroups: [],
                startDate: new Date(this.startDate.value),
                endDate: new Date(this.endDate.value),
                name: this.courseTitle.value,
                id: ""
            }).subscribe(
                response => {
                    if (response) {
                        this.dialogRef.close(response);
                    }
                });
    }

    public updateCourse() {
        this.service.update(
            {
                state: this.getState(),
                taskGroups: this.data.created.taskGroups,
                startDate: new Date(this.startDate.value),
                endDate: new Date(this.endDate.value),
                name: this.courseTitle.value,
                id: this.data.created.id
            }).subscribe(
                response => {
                    if (response) {
                        this.dialogRef.close(response);
                    }
                });
    }

    private initUpdateState() {
        this.courseTitle = new FormControl(this.data.created.name, [Validators.required]);
        this.startDate = new FormControl(new Date(this.data.created.startDate), [Validators.required]);
        this.endDate = new FormControl(new Date(this.data.created.endDate), [Validators.required]);
        this.courseState = new FormControl(true);

        if (this.data.created.state === Active.toString())
          this.state = "Aktiv";
        else
          this.state = "Inaktiv";
    }

    private getState(): string {
      return this.state === "Aktiv" ? CourseState.Active.toString() : CourseState.Inactive.toString();
    }

    private initCreateState() {
        this.courseTitle = new FormControl('', [Validators.required]);
        this.startDate = new FormControl('', [Validators.required]);
        this.endDate = new FormControl('', [Validators.required]);
        this.courseState = new FormControl(true);
        this.state = "Aktiv";

        var dummy: CourseDto =
        {
            name: "ingen kurs vald",
            id: "none",
            endDate: new Date(),
            startDate: new Date(),
            state: "Invalid",
            taskGroups: []
        };

        this.selectableCourses = Object.assign([], this.data.courses.filter(c=>c.state === Active.toString()));
        this.selectableCourses.splice(0, 0, dummy);
        this.selectedCopy = dummy;
    }
}
