import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from "../common/notification.service";
import { Constants } from "../models/constants";
import { Role, ResultState, CourseResultState } from "../models/enum";
import { CourseResultExtendedDto } from "../models/CourseResultExtendedDto";
import { TaskGroupDto } from '../models/TaskGroupDto';
import { TaskGroupResultDto } from '../models/TaskGroupResultDto';
import { HelperService, IDuration } from '../common/helper.service';
import { TaskGroupResultService } from '../services/task-group-result.service';
import { UserDto } from "../models/UserDto";
import { CourseResultDialogComponent } from "./course-result-dialog/course-result-dialog.component";
import { DialogService } from '../common/dialog.service';
import { TooltipConstants } from "../common/tooltip.constants";
import { TaskGroupData } from "./task-group/task-group.component";
import Filed = ResultState.Filed;

export interface IListItem {
  state: string,
  type: string,
  name: string,
  startDate: Date,
  endDate: Date,

  highlighted?: boolean,
  payload: TaskGroupResultDto,
}

@Component({
  selector: 'result-list',
  templateUrl: './result-list.component.html',
  styleUrls: ['./field-course-result.component.css']
})
export class ResultListComponent {

  readonly dayInMilSecs = 1000 * 60 * 60 * 24;

  public weeklyEnabled = false;

  public duration: IDuration = { start: new Date(), end: new Date() }

  public currentWeek: number;

  public displayedColumns: string[] = ["type", "name", "start", "end", "state", "remove"];

  public resultDataSource: MatTableDataSource<IListItem> = new MatTableDataSource([]);

  public results: IListItem[] = [];

  public selectedView: string = "weekly";

  public taskGroupActive: boolean = false;

  taskGroupsByType: { [type: string]: Array<TaskGroupDto>; } = {};

  taskGroupData: TaskGroupData;

  @Input() fcResult: CourseResultExtendedDto;
  @Input() currentUser: UserDto;
  @Input() selectedDuration: IDuration;
  @Output() refreshResult: EventEmitter<TaskGroupResultDto> = new EventEmitter();

  @ViewChild("paginator", { static: true }) paginator: MatPaginator;
  @ViewChild("sort", { static: true }) sort: MatSort;

  constructor(private dialogService: DialogService, private tgrService: TaskGroupResultService, public constants: Constants, public tooltipConst: TooltipConstants,
    private notifier: NotificationService, public dialog: MatDialog) {
    this.currentWeek = HelperService.ISO8601_week_no(new Date);
    this.initDates();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["selectedDuration"] && this.selectedDuration != null) {
      this.duration = this.selectedDuration;
      this.onWeeklyFilter("weekly");
      this.currentWeek = HelperService.ISO8601_week_no(this.duration.start);
    }
    if (changes["fcResult"] && this.fcResult != null) {
      this.buildDataSource(this.fcResult.taskGroupResults);
      this.onWeeklyFilter("weekly");
      this.currentWeek = HelperService.ISO8601_week_no(this.duration.start);
    }
    if (changes["currentUser"] && this.currentUser != null) {
      this.initRole();
    }

  }

  receiveMessage(event: TaskGroupResultDto) {
    this.buildDataSource(this.fcResult.taskGroupResults);
    this.refreshResult.emit(event);
  }

  public isInRole(role: string) {
    if (this.currentUser === null) return false;

    return this.currentUser.role.toString() === role;
  }

  public isReadOnly(): boolean {
    return this.fcResult.state === CourseResultState.Inactive.toString() ||
      this.fcResult.state === CourseResultState.Completed.toString();
  }

  public canDelete(result: TaskGroupResultDto): boolean {

    return result.state === ResultState.Started.toString();
  }

  public canSignOrReject(result: TaskGroupResultDto) {
    return result.state === ResultState.Filed.toString();
  }

  public deleteResult(toDelete: IListItem, event) {
    event.stopPropagation();
    const dialogRef = this.dialogService.openDeleteConfirmationDialog(null);
    dialogRef.afterClosed().subscribe(result => {
      if (!result) return;
      this.tgrService.delete(toDelete.payload.id).subscribe(result => {
        if (result) {
          this.remove(toDelete.payload);
          this.notifier.showSuccess(toDelete.name + " borttagen");
        }
      });
    });
  }

  public rejectResult(toReject: IListItem, event) {
    event.stopPropagation();
    toReject.payload.state = ResultState.Rejected.toString();
    this.tgrService.update(toReject.payload).subscribe(result => {
      if (result) {
        this.refresh(result);
        this.refreshResult.emit();
        this.notifier.showSuccess(result.taskGroup.name + " uppdaterad");
      }
    });
  }

  public signResult(toSign: IListItem, event) {
    event.stopPropagation();
    toSign.payload.state = ResultState.Signed.toString();
    this.tgrService.update(toSign.payload).subscribe(result => {
      if (result) {
        this.refresh(result);
        this.refreshResult.emit();
        this.notifier.showSuccess(result.taskGroup.name + " uppdaterad");
      }
    });
  }

  public signWeekly(): void {
    const toSign = this.fcResult.taskGroupResults.filter(
      tgr => new Date(tgr.startDate).getTime() >= new Date(this.duration.start).getTime() &&
        new Date(tgr.endDate).getTime() <= new Date(this.duration.end).getTime() && tgr.state === Filed.toString());
    for (let item of toSign) {
      item.state = ResultState.Signed.toString();
    }
    this.tgrService.signMany(toSign).subscribe(result => {
      if (result) {
        this.refreshMany(result);
        this.refreshResult.emit();
        result.length === toSign.length ? this.notifier.showSuccess("Alla är klarmarkerade") : this.notifier.showError("Kan inte klarmarkera alla");
      }
    });
  }

  public getWeek(date: Date): string {
    if (date == null) return "";
    return HelperService.ISO8601_week_no(new Date(date)).toString();
  }

  public onAddClicked(): void {

    if (!this.fcResult.course.taskGroups || this.fcResult.course.taskGroups.length === 0) {
      this.notifier.showError("Finns inga moment registrerade");
      return;
    }
    this.taskGroupData = { taskGroups: this.fcResult.course.taskGroups, user: this.currentUser, courseResult: this.fcResult, result: null };
    this.taskGroupActive = true;
    //const dialogRef = this.dialog.open(CourseResultDialogComponent, {
    //    panelClass: 'custom-dialog-container',
    //    data: { taskGroups: this.fcResult.course.taskGroups, user: this.currentUser, courseResult: this.fcResult },
    //    disableClose: true,
    //    //maxHeight:'30%',
    //    //position:{ top: '10px', bottom:'150px' }
    //});

    //dialogRef.afterClosed().subscribe(result => {
    //    if (!result) return;
    //    this.refresh(result);
    //    this.refreshResult.emit();
    //});
  }

  public onTaskResultClick(result: IListItem) {
    this.taskGroupData = { taskGroups: this.fcResult.course.taskGroups, user: this.currentUser, courseResult: this.fcResult, result: result.payload };
    this.taskGroupActive = true;
    //const dialogRef = this.dialog.open(CourseResultDialogComponent,
    //  {
    //    panelClass: 'custom-dialog-container',
    //    data: {
    //      taskGroups: this.fcResult.course.taskGroups,
    //      user: this.currentUser,
    //      courseResult: this.fcResult,
    //      result: result.payload
    //    },
    //    disableClose: true,
    //    //maxHeight:'30%',
    //    //position:{ top: '10px', bottom:'30px' }

    //  });

    //dialogRef.afterClosed().subscribe(result => {
    //  if (!result) return;
    //  this.refresh(result);
    //  this.refreshResult.emit();
    //});
  }

  public onWeeklyFilter(value: string) {
    if (!this.duration) return;
    if (value === "weekly") {
      this.resultDataSource.filterPredicate = (data: IListItem) => new Date(data.endDate).getTime() >= new Date(this.duration.start).getTime()
        && new Date(data.startDate).getTime() <= new Date(this.duration.end).getTime();
      this.resultDataSource.filter = '1';
      this.weeklyEnabled = true;
    }
    else {
      this.currentWeek = HelperService.ISO8601_week_no(new Date);
      this.initDates();
      this.resultDataSource.filter = "";
      this.weeklyEnabled = false;
    }
    if (this.resultDataSource.paginator) {
      this.resultDataSource.paginator.firstPage();
    }
    this.selectedView = value;
  }

  public prevWeek(): void {
    this.duration.start.setDate(this.duration.start.getDate() - 7);
    this.duration.end.setDate(this.duration.end.getDate() - 7);
    this.refreshDates();
    this.currentWeek = HelperService.ISO8601_week_no(this.duration.start);
    this.applyCustomFilter();
  }

  public nextWeek(): void {
    this.duration.start.setDate(this.duration.start.getDate() + 7);
    this.duration.end.setDate(this.duration.end.getDate() + 7);
    this.refreshDates();
    this.currentWeek = HelperService.ISO8601_week_no(this.duration.start);
    this.applyCustomFilter();
  }

  public sortColumn(sort: Sort): void {
    if (sort.active === "start") {
      if (sort.direction === "asc")
        this.resultDataSource.data.sort((a, b) => { return compare(a.startDate, b.startDate, true) });
      if (sort.direction === "desc")
        this.resultDataSource.data.sort((a, b) => { return compare(a.startDate, b.startDate, false) });
    }
    if (sort.active === "end") {
      if (sort.direction === "asc")
        this.resultDataSource.data.sort((a, b) => { return compare(a.endDate, b.endDate, true) });
      if (sort.direction === "desc")
        this.resultDataSource.data.sort((a, b) => { return compare(a.endDate, b.endDate, false) });
    }
  }

  public applyFilter(filterValue: string) {
    if (this.weeklyEnabled) return;
    this.resultDataSource.filterPredicate = (data, filter: string): boolean => data.name.toLowerCase().includes(filter) || data.state.toLowerCase().includes(filter);
    this.resultDataSource.filter = filterValue.trim().toLowerCase();

    if (this.resultDataSource.paginator) {
      this.resultDataSource.paginator.firstPage();
    }
  }

  public receiveResult(result: TaskGroupResultDto) {

    this.taskGroupActive = false;
    if (!result) return;
    this.refresh(result);
    this.refreshResult.emit();
  }

  private refresh(result: TaskGroupResultDto): void {
    let found = this.fcResult.taskGroupResults.find(tgr => tgr.id === result.id);
    if (found) found = result;
    else
      this.fcResult.taskGroupResults.push(result);
    this.buildDataSource(this.fcResult.taskGroupResults);
  }

  private refreshMany(results: TaskGroupResultDto[]) {
    for (let result of results) {
      let found = this.fcResult.taskGroupResults.find(tgr => tgr.id === result.id);
      if (found) found = result;
      else
        this.fcResult.taskGroupResults.push(result);
    }
    this.buildDataSource(this.fcResult.taskGroupResults);
  }

  private applyCustomFilter(): void {
    this.resultDataSource.filterPredicate = (data: IListItem) => new Date(data.endDate).getTime() >= new Date(this.duration.start).getTime()
      && new Date(data.startDate).getTime() <= new Date(this.duration.end).getTime();
    this.resultDataSource.filter = '1';
    if (this.resultDataSource.paginator) {
      this.resultDataSource.paginator.firstPage();
    }
  }

  private buildDataSource(data: TaskGroupResultDto[]) {
    this.results = [];
    if (this.fcResult !== null) {
      for (let result of data) {
        const toAdd: IListItem = {
          highlighted: false,
          payload: result,
          endDate: result.endDate,
          startDate: result.startDate,
          name: result.taskGroup.name,
          state: result.state,
          type: result.taskGroup.groupType
        };

        this.results.push(toAdd);
      }
    }
    this.resultDataSource = new MatTableDataSource(this.results);
    this.resultDataSource.paginator = this.paginator;
    this.resultDataSource.sort = this.sort;

    if (this.weeklyEnabled) this.applyCustomFilter();

    this.onWeeklyFilter(this.selectedView);
  }

  private initDates() {
    this.duration.start = new Date();
    this.duration.end = new Date();
    const d = new Date();
    const n = d.getDay();
    if (n === 0) this.duration.start = new Date(d.getTime() - 6 * this.dayInMilSecs);
    else
      this.duration.start = new Date(d.getTime() - (n * this.dayInMilSecs - this.dayInMilSecs));

    this.duration.end = new Date(this.duration.start.getTime() + (this.dayInMilSecs * 6));
    this.duration.start.setHours(0, 0, 0, 0);
    this.duration.end.setHours(0, 0, 0, 0);
    
    //this.refreshDates();
  }

  // ensures the model binding
  private refreshDates(): void {
    this.duration.start = new Date(this.duration.start.getTime());
    this.duration.end = new Date(this.duration.end.getTime());
  }

  private remove(removed: TaskGroupResultDto): void {
    const index = this.fcResult.taskGroupResults.indexOf(removed);
    if (index > -1) {
      this.fcResult.taskGroupResults.splice(index, 1);
    }
    this.buildDataSource(this.fcResult.taskGroupResults);
  }

  private initRole(): void {
    switch (this.currentUser.role) {
      case Role.Student.toString():
        {
          // Default setting 
          break;
        }
      case Role.Administrator.toString():
        {
          this.displayedColumns = ["type", "name", "start", "end", "state", "reject", "sign"];
          this.weeklyEnabled = true;
          this.onWeeklyFilter("weekly");
          break;
        }
      case Role.Supervisor.toString():
        {
          this.displayedColumns = ["type", "name", "start", "end", "state", "reject", "sign"];
          this.weeklyEnabled = true;
          this.onWeeklyFilter("weekly");
          break;
        }
      case Role.Teacher.toString():
        {
          this.displayedColumns = ["type", "name", "start", "end", "state"];
          this.weeklyEnabled = true;
          this.onWeeklyFilter("weekly");
          break;
        }
      default:
    }
  }
}

function compare(a: Date, b: Date, isAsc: boolean) {
  return (new Date(a).getTime() < new Date(b).getTime() ? -1 : 1) * (isAsc ? 1 : -1);
}
