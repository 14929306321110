import { Component, OnInit, Input, ViewChild, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CourseDto } from "../../models/CourseDto";
import { MatSort } from "@angular/material/sort";
import { MatPaginator}from "@angular/material/paginator";
import { MatTableDataSource} from "@angular/material/table";
import { CourseService } from "../../services/course.service";
import { NotificationService } from "../../common/notification.service";
import { CourseState } from "../../models/enum";

@Component({
    selector: 'inactive-courses',
    templateUrl: './inactive-courses.component.html',
    styleUrls: ['./inactive-courses.component.scss']
})
export class InactiveCoursesComponent implements OnInit {

    public inactiveDataSource = new MatTableDataSource<CourseDto>([]);

    public displayedColumns: string[] = ["name", "startDate", "endDate", "remove"];

    @Input() inactiveCourses: CourseDto[] = [];

    @Input() courses: CourseDto[] = [];

    @Output() refreshTrigger = new EventEmitter<any>();

    @ViewChild("paginator", { static: true }) paginator: MatPaginator;
    @ViewChild("sort", { static: true }) sort: MatSort;

    constructor(private readonly courseService: CourseService, private readonly notifier: NotificationService) {

    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["inactiveCourses"]) {
            this.inactiveDataSource = new MatTableDataSource<CourseDto>(this.inactiveCourses);
            this.inactiveDataSource.paginator = this.paginator;
            this.inactiveDataSource.sort = this.sort;
        }
    }

    public applyFilter(filterValue: string) {
        this.inactiveDataSource.filter = filterValue.trim().toLowerCase();

        if (this.inactiveDataSource.paginator) {
            this.inactiveDataSource.paginator.firstPage();
      }
    }

    public onActivate(course: CourseDto): void {
        const updated = Object.assign({}, course);
        updated.state = CourseState.Active.toString();
        this.courseService.update(updated).subscribe(response => {
            if (response) {
                const index = this.courses.indexOf(course);
              if (index !== -1) {
                  this.courses.splice(index, 1, response);
                  this.notifier.showSuccess(response.name + " aktiverad");
                  this.refreshTrigger.emit(response);
              }  
            }
        });
    }
}
