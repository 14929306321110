import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class TooltipConstants {

    public delete = "Ta bort";

    public edit = "Redigera";

    public cancel = "Ångra";

    public reject = "Komplettera";

    public sign = "Signera";

    public getTaskGroupState(state: string): string {
        switch (state) {
            case ("Invalid"):
                return "Ogiltig";
            case ("Started"):
                return "Sparad";
            case ("Filed"):
                return "Inlämnad";
            case ("Signed"):
                return "Klarmarkerad";
            case ("Rejected"):
                return "Komplettering";
            default:
                return "Ogiltig";
        }
    }

    public getTaskGroupType(type: string): string {
        switch (type) {
            case ("Invalid"):
                return "Ogiltig";
            case ("Clinical"):
                return "Kliniskt arbete";
            case ("Exercise"):
                return "Övningsarbete";
            case ("Diary"):
                return "Utvärdering";
            case ("Absence"):
                return "Frånvaro";
            default:
                return "Ogiltig";
        }
    }

    public getRole(role: string): string {
        switch (role) {
            case ("Invalid"):
                return "Ogiltig";
            case ("Student"):
                return "Student";
            case ("Administrator"):
                return "Administratör";
            case ("Supervisor"):
                return "Handledare";
            case ("Teacher"):
                return "Lärare";
            default:
                return "Ogiltig";
        }
    }

    public getTaskItemType(type: string): string {
        switch (type) {
            case ("Invalid"):
                return "Ogiltig";
            case ("Text"):
                return "Text";
            case ("Amount"):
                return "Antal";
            case ("Happiness"):
                return "Smiley skala";
            default:
                return "Ogiltig";
        }
    }

    public getCourseResultState(state: string): string {
        switch (state) {
            case ("Invalid"):
                return "Ogiltig";
            case ("Active"):
                return "Aktiv";
            case ("Inactive"):
                return "Inaktiv";
            case ("Completed"):
                return "Avslutad";
            default:
                return "Ogiltig";
        }
    }
}
