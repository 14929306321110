import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common'
import { CourseResultDto } from "../models/CourseResultDto";
import { TaskGroupResultDto } from "../models/TaskGroupResultDto";
import { TaskType } from "../models/enum";
import { TooltipConstants } from "../common/tooltip.constants";
import { Workbook, Worksheet, Row } from 'exceljs';

import * as fs from 'file-saver';
import { CommentDto } from '../models/CommentDto';
import Clinical = TaskType.Clinical;
import Exercise = TaskType.Exercise;

@Injectable({
  providedIn: 'root'
})
export class ExcelExportService {

  private results: CourseResultDto;

  constructor(private datepipe: DatePipe, private tooltip:TooltipConstants) { }

  public generate(results: CourseResultDto) {
    this.results = results;
    this.writeToFile(this.getWorkbook());

  }

  private getWorkbook(): any {

    const excel = require('exceljs');
    const workBook = new excel.Workbook();
debugger
    this.generateMainSheets(workBook.addWorksheet(this.tooltip.getTaskGroupType(Clinical.toString())), workBook.addWorksheet(this.tooltip.getTaskGroupType(Exercise.toString())), this.results.taskGroupResults);
    this.generateCommentarySheet(workBook.addWorksheet('Kommentarer'), this.results.comments);
    

    return workBook;
  }

  private generateMainSheets(clinical: Worksheet, exercise: Worksheet, results: TaskGroupResultDto[]): void {
    const cTitleRow = this.createTitleRow(clinical, [this.tooltip.getTaskGroupType(Clinical.toString())]);
    const eTitleRow = this.createTitleRow(exercise, [this.tooltip.getTaskGroupType(Exercise.toString())]);

    //const cHeaderRow = this.createHeaderRow(clinical, ["Uppgift", "Svar"]);
    //const eHeaderRow = this.createHeaderRow(exercise, ["Uppgift", "Svar"]);
    for (let tg of results) {
      if (tg.taskGroup.groupType === Clinical.toString()) {
        this.createSubTitleRow(clinical, [tg.taskGroup.name + " " + this.datepipe.transform(tg.startDate, 'yyyy-MM-dd') + " - " + this.datepipe.transform(tg.endDate, 'yyyy-MM-dd')]);
        for (let tr of tg.taskResults) {
          clinical.addRow([tr.originalQuery, tr.answer], null);
        }
      }
      if (tg.taskGroup.groupType === TaskType.Exercise.toString()) {
        this.createSubTitleRow(exercise, [tg.taskGroup.name + " " + this.datepipe.transform(tg.startDate, 'yyyy-MM-dd') + " - " + this.datepipe.transform(tg.endDate, 'yyyy-MM-dd')] );
        for (let tr of tg.taskResults) {
          exercise.addRow([tr.originalQuery, tr.answer], null);
        }
      }
    }
  }

  private generateCommentarySheet(worksheet: Worksheet, comments: CommentDto[]): void {

    const titleRow = this.createTitleRow(worksheet, ["Kommentarer"]);
    const headerRow = this.createHeaderRow(worksheet, ["Datum", "Text"]);
    for (let comment of comments) {
      const addedRow = worksheet.addRow([this.datepipe.transform(comment.date, 'yyyy-MM-dd'), comment.text], null);
    }
  }

  private writeToFile(workBook: any) {
    workBook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, this.results.course.name +'.xlsx');
    });
  }

  private createSubTitleRow(worksheet: Worksheet, row: string[]): Row {
    const titleRow = worksheet.addRow(row, null);
    titleRow.font = { size: 12, bold: true };
    //worksheet.mergeCells(titleRow.getCell('A').name + ':' + titleRow.getCell('D').name);
    return titleRow;
  }

  private createHeaderRow(worksheet: Worksheet, row:string[]) :Row{
    const headerRow = worksheet.addRow(row, null);
    headerRow.eachCell((cell, number) => {
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
    });
    return headerRow;
  }

  private createTitleRow(worksheet: Worksheet, row: string[]): Row {
    const titleRow = worksheet.addRow(row, null);
    titleRow.font = { size: 16, bold: true };
    worksheet.mergeCells('A1:D1');

    return titleRow;
  }
}
