import { UserDto } from "../models/UserDto";
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ICourseManagerMessage {
  currentUser: UserDto;
}

@Injectable({ providedIn: 'root' })
export class CourseManagerDataService {
  // string message
  private messageSource = new BehaviorSubject<any>(null);

  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(message: ICourseManagerMessage) {
    this.messageSource.next(message);
  }

}
