<mat-card *ngIf="taskGroup" style="margin-right: 5px; margin-bottom: 5px; ">
  <mat-card-header>
    <div mat-card-avatar><mat-icon color="primary">{{constants.getTaskGroupTypeIcon(taskGroup.groupType)}}</mat-icon></div>
    <mat-card-title>{{taskGroup.name}}</mat-card-title>
    <mat-card-subtitle>{{tooltipConst.getTaskGroupType(taskGroup.groupType)}}</mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div *ngIf="selectableList" style="margin-left: 5px; margin-right: 5px; overflow: auto; height: 400px">
      <mat-list>
        <mat-list-item *ngFor="let item of selectableList" (click)="selectItem(item)" [ngClass]="{highlighted: item.highlighted}">
          <mat-icon mat-list-icon color="primary">{{constants.getTaskItemTypeIcon(item.payload.taskType)}}</mat-icon>
          <p mat-line>{{item.payload.query}}</p>
          <p mat-line style="font-size: 12px">{{tooltipConst.getTaskItemType(item.payload.taskType)}}</p>
          <button mat-icon-button style="font-size: small" (click)="deleteItem(item.payload, $event)"><mat-icon color="primary">delete</mat-icon></button>
          <mat-divider></mat-divider>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <mat-expansion-panel class="mat-elevation-z" hideToggle="true" [expanded]="selectedItem">
      <mat-expansion-panel-header>
        <mat-panel-title *ngIf="!selectedItem">
          Lägg till
        </mat-panel-title>
        <mat-panel-title *ngIf="selectedItem">
          Ändra
        </mat-panel-title>
        <mat-panel-description *ngIf="!selectedItem">
          Skapa en ny fråga
        </mat-panel-description>
        <mat-panel-description *ngIf="selectedItem">
          Redigera befintlig
        </mat-panel-description>
        <mat-icon *ngIf="!selectedItem" color="accent">add_circle_outline</mat-icon>
        <mat-icon *ngIf="selectedItem" color="accent">edit</mat-icon>
      </mat-expansion-panel-header>
      <form>
        <div fxLayout="column">
          <mat-form-field style="width: 50%">
            <mat-select name="typeSelector" placeholder="Typ" required [(ngModel)]="selectedType">
              <mat-select-trigger>
                {{tooltipConst.getTaskItemType(selectedType)}}
              </mat-select-trigger>
              <mat-option *ngFor="let type of taskItemTypes" [value]="type"><mat-icon>{{constants.getTaskItemTypeIcon(type)}}</mat-icon>{{tooltipConst.getTaskItemType(type)}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field style="width: 100%">
            <textarea matInput style="height: 60px" placeholder="Text" [formControl]="taskQuery"></textarea>
          </mat-form-field>
        </div>
      </form>
      <mat-action-row>
        <button mat-button color="primary" (click)="resetInput()">Ångra</button>
        <button mat-button color="accent" [hidden]="selectedItem" (click)="createTask()" [disabled]="selectedType == null || !taskQuery.valid">Lägg till</button>
        <button mat-button color="accent" [hidden]="!selectedItem" [disabled]="selectedType == null || !taskQuery.valid" (click)="updateTask()">Spara</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-card-actions>
</mat-card>
