import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../services/account.service';
import { BaseComponent } from "../common/base.component";
import { UserDto } from "../models/UserDto";
import { CourseResultDto } from "../models/CourseResultDto";
import { NavBarDataService, IStudentMessage } from "./nav-bar-data.service";
import { Role } from "../models/enum";
import { CourseManagerDataService } from "../course-manager/course-manager-data.service";
import { IStudentProgressMessage, StudentProgressDataService } from "../student-progress/student-progress-data.service";
import { UserProfileDataService, IUserProfileMessage } from "../user-profile/user-profile-data.service";
import { UserPreferenceService } from "../services/user-preference.service";
import Administrator = Role.Administrator;
import { UserPreferenceDto } from '../models/UserPreferenceDto';
import { LoginDataService, ILoginMessage } from '../login/login-data.service';
import Student = Role.Student;
import Supervisor = Role.Supervisor;

export class MenuItem {
    constructor(public title: string, public icon: string, public subMenu: MenuItem[], public arg: any, public onClick: (arg: any) => any) {
    }
    isSubMenu: boolean = false;
}

@Component({
    selector: 'nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

    user: UserDto;

    menuItems: MenuItem[];

    constructor(private accountService: AccountService, private router: Router, private dataService: NavBarDataService, private courseManagerDataService: CourseManagerDataService,
        private studentProgressDataService: StudentProgressDataService, private userProfileDataService: UserProfileDataService, private userPreferenceService: UserPreferenceService,
        private loginDataService: LoginDataService) {
    }

    ngOnInit() {
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        if (!currentUser || !currentUser.token) {
            this.loginDataService.currentMessage.subscribe(
                message => {
                    if (message && (message as ILoginMessage).loggedIn) {
                        this.applicationStartup();
                    }
                });
        } else
            this.applicationStartup();
    }

    public onProfileClick(): void {
        this.userProfileDataService.changeMessage({ currentUser: this.user });
        this.router.navigate(["user-profile"]);
    }

    public onCourseManagerClick(): void {
        this.courseManagerDataService.changeMessage({ currentUser: this.user });
        this.router.navigate(['course-manager']);
    }

    public onUserManagerClick() {
        this.router.navigate(['user-manager']);
    }

    public onCourseResultClick(cr: CourseResultDto) {
        if (this.user.role === Student.toString()) {
            this.dataService.changeMessage({ courseResultId: cr.id, currentUser: this.user });
            this.router.navigate(['field-course-result']);
        }
        if (this.user.role === Supervisor.toString() || this.user.role === Role.Teacher.toString()) {
            const message: IStudentProgressMessage = { course: cr.course, currentUser: this.user };
            this.studentProgressDataService.changeMessage(message);
            this.router.navigate(['student-progress']);
        }
        this.updateUserPreference(cr);
    }

    private applicationStartup(): void {
        this.accountService.getUser().subscribe(user => {
            if (user) {
                this.user = user;
                this.routeUser();
            }
        });

        this.userProfileDataService.currentMessage.subscribe(message => {
            if (message as IUserProfileMessage) {
                this.user = (message as IUserProfileMessage).currentUser;
            }
        });
    }

    private routeUser(): void {
        if (this.user.userPreference) {
            if (!this.user.userPreference.currentCourseResult) {
                this.dataService.changeMessage({ courseResultId: null, currentUser: this.user });
                this.onProfileClick();
            } else if (this.user.role === Student.toString() || this.user.role === Supervisor.toString() || this.user.role === Role.Teacher.toString()) {
                this.openCourseResults(this.user.userPreference.currentCourseResult);
            } else if (this.user.role === Role.Administrator.toString()) {
                this.onCourseManagerClick();
            }
        }
    }

    private openCourseResults(cr: CourseResultDto) {
        if (this.user.role === Student.toString()) {
            this.dataService.changeMessage({ courseResultId: cr.id, currentUser: this.user });
            this.router.navigate(['field-course-result']);
        }
        if (this.user.role === Supervisor.toString() || this.user.role === Role.Teacher.toString() || this.user.role === Role.Administrator.toString()) {
            const message: IStudentProgressMessage = { course: cr.course, currentUser: this.user };
            this.studentProgressDataService.changeMessage(message);
            this.router.navigate(['student-progress']);
        }
        this.updateUserPreference(cr);
    }

    //private buildMenu() {
    //    switch (this.user.role) {
    //        case Role.Invalid.toString():
    //            break;
    //        case Role.Administrator.toString():
    //            this.generateAdminMenu();
    //            break;
    //        case Role.Student.toString():
    //            this.generateStudentMenu();
    //            break;
    //        case Role.Teacher.toString():
    //            this.generateTeacherMenu();
    //            break;
    //        case Role.Supervisor.toString():
    //            this.generateSupervisorMenu();
    //            break;
    //        default:
    //    }
    //}

    //private generateStudentMenu() {
    //    this.menuItems = [];
    //    this.menuItems.push(new MenuItem("Profil", "person_outline", null, null, f => {
    //        this.userProfileDataService.changeMessage({ currentUser: this.user });
    //        this.router.navigate(["user-profile"]);
    //    }));

    //    const subMenu = new MenuItem("Kurser", "school", new Array<MenuItem>(), null, null);
    //    this.menuItems.push(subMenu);
    //    for (let courseResult of this.user.courseResults) {
    //        subMenu.subMenu.push(new MenuItem(courseResult.course.name, null, null, courseResult, (arg: any): void => {
    //            let result = arg as CourseResultDto;
    //            if (result != null) {
    //                this.dataService.changeMessage({ courseResultId: result.id, currentUser: this.user });
    //                this.router.navigate(['field-course-result']);
    //                this.updateUserPreference(courseResult);
    //            }
    //        }));
    //    }
    //    subMenu.isSubMenu = true;
    //}

    //private generateAdminMenu() {
    //    this.menuItems = [];
    //    this.menuItems.push(new MenuItem("Profil", "person_outline", null, null, f => {
    //        this.userProfileDataService.changeMessage({ currentUser: this.user });
    //        this.router.navigate(["user-profile"]);
    //    }));
    //    this.menuItems.push(new MenuItem("Användare", "people_outline", null, null,
    //        () => {
    //            this.router.navigate(['user-manager']);
    //        }));
    //    this.menuItems.push(new MenuItem("Kurser", "folder_open", null, null,
    //        () => {
    //            this.router.navigate(['course-manager']);
    //        }));
    //}

    //private generateSupervisorMenu(): void {
    //    this.menuItems = [];
    //    this.menuItems.push(new MenuItem("Profil", "person_outline", null, null, f => {
    //        this.userProfileDataService.changeMessage({ currentUser: this.user });
    //        this.router.navigate(["user-profile"]);
    //    }));
    //    const subMenu = new MenuItem("Kurser", "school", new Array<MenuItem>(), null, null);
    //    this.menuItems.push(subMenu);
    //    for (let courseResult of this.user.courseResults) {
    //        subMenu.subMenu.push(new MenuItem(courseResult.course.name, null, null, courseResult, (arg: any): void => {
    //            let result = arg as CourseResultDto;
    //            if (result != null) {
    //                const message: IStudentProgressMessage = { course: courseResult.course, currentUser: this.user };
    //                this.studentProgressDataService.changeMessage(message);
    //                this.router.navigate(['student-progress']);
    //                this.updateUserPreference(courseResult);
    //            }
    //        }));
    //    }
    //    subMenu.isSubMenu = true;
    //}

    //private generateTeacherMenu(): void {
    //    this.menuItems = [];
    //    this.menuItems.push(new MenuItem("Profil", "person_outline", null, null, f => {
    //        this.userProfileDataService.changeMessage({ currentUser: this.user });
    //        this.router.navigate(["user-profile"]);
    //    }));
    //    const subMenu = new MenuItem("Kurser", "school", new Array<MenuItem>(), null, null);
    //    this.menuItems.push(subMenu);
    //    for (let courseResult of this.user.courseResults) {
    //        subMenu.subMenu.push(new MenuItem(courseResult.course.name, null, null, courseResult, (arg: any): void => {
    //            let result = arg as CourseResultDto;
    //            if (result != null) {
    //                const message: IStudentProgressMessage = { course: courseResult.course, currentUser: this.user };
    //                this.studentProgressDataService.changeMessage(message);
    //                this.router.navigate(['student-progress']);
    //                this.updateUserPreference(courseResult);
    //            }
    //        }));
    //    }
    //    subMenu.isSubMenu = true;
    //}

    private updateUserPreference(courseResult: CourseResultDto): void {
        if (this.user.userPreference === null)
            this.user.userPreference = new UserPreferenceDto();

        if (this.user.userPreference.currentCourseResult &&
            this.user.userPreference.currentCourseResult.id === courseResult.id) return;

        if (this.user.userPreference.id) {
            const updated = Object.assign({}, this.user.userPreference);
            updated.currentCourseResult = courseResult;
            this.userPreferenceService.update(updated).subscribe(result => {
                if (result) {
                    this.user.userPreference = result;
                }
            });
        } else
            this.userPreferenceService.create(this.user.id, { currentCourseResult: courseResult, id: undefined }).subscribe(result => {
                if (result)
                    this.user.userPreference = result;
            });
    }
}
