import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseDto } from "../models/CourseDto";
import { UserDto } from "../models/UserDto";
import { AccountService } from "../services/account.service";
import { CourseService } from "../services/course.service";
import { MatDialog } from "@angular/material/dialog";
import { TaskGroupResultDto } from "../models/TaskGroupResultDto";
import { ResultState, CourseState } from "../models/enum";
import { AddNewCourseDialogComponent } from "./add-new-course-dialog/add-new-course-dialog.component";
import { NotificationService } from "../common/notification.service";
import { DialogService } from "../common/dialog.service";
import { StudentProgressDataService } from "../student-progress/student-progress-data.service";
import { CourseManagerDataService, ICourseManagerMessage } from "./course-manager-data.service";
import { IStudentMessage } from "../nav-bar/nav-bar-data.service";
import Active = CourseState.Active;

@Component({
    selector: 'course-manager',
    templateUrl: './course-manager.component.html',
    styleUrls: ['./course-manager.component.css']
})
export class CourseManagerComponent implements OnInit {

    public currentUser: UserDto;

    public users: UserDto[];

    public courses: CourseDto[] = [];

    public activeCourses: CourseDto[] = [];

    public inactiveCourses: CourseDto[] = [];

    constructor(private readonly accountService: AccountService,
        private readonly courseService: CourseService,
        private readonly courseManagerDataService: CourseManagerDataService) {
        this.courseManagerDataService.currentMessage.subscribe(message => {
            if (message != null) {
                if (message as ICourseManagerMessage) {
                    this.currentUser = (message as ICourseManagerMessage).currentUser;
                }
            }
        });

        this.getUsers();
        this.getCourses();
    }

    ngOnInit() {

    }

    public refreshEvent(event: CourseDto) {
        this.sortCourses();
    }

    private getUsers() {
        this.accountService.getActive().subscribe(result => {
            if (result != null) {
                this.users = result;
            }
        });
    }

    private getCourses() {
        this.courseService.getAll().subscribe(result => {
            if (result != null) {
                this.courses = result;
                this.sortCourses();
            }
        });
    }

    private sortCourses(): void {
        this.activeCourses = [];
        this.inactiveCourses = [];
        for (let course of this.courses) {
            course.state === Active.toString() ? this.activeCourses.push(course) : this.inactiveCourses.push(course);
        }
    }
}
