import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { TaskGroupDto } from "../models/TaskGroupDto";
import { TaskItemDto } from "../models/TaskItemDto";

@Injectable({ providedIn: 'root' })
export class TaskItemService extends BaseService {

  constructor(http: HttpClient) {
    super(http);

    this.controllerUrl = '/taskitem';
  }

  create(taskGroupId: string, created: TaskItemDto): Observable<TaskItemDto> {
    return this.http.put<TaskItemDto>(this.apiUrl + this.controllerUrl + "/create/?taskGroupId=" + taskGroupId, created, { withCredentials: true });
  }

  update(updated: TaskItemDto): Observable<TaskItemDto> {
    return this.http.post<TaskItemDto>(this.apiUrl + this.controllerUrl + "/update", updated, { withCredentials: true });
  }

  delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(this.apiUrl + this.controllerUrl + "/delete/?id=" + id, { withCredentials: true });
  }
}
