import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TaskGroupResultDto } from "../models/TaskGroupResultDto";
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TaskGroupResultService extends BaseService {

  constructor(http: HttpClient) {
    super(http);

    this.controllerUrl = '/taskgroupresult';
  }

  create(resultGuid: string, toCreate: TaskGroupResultDto): Observable<TaskGroupResultDto> {
    return this.http.post<TaskGroupResultDto>(this.apiUrl + this.controllerUrl + "/create/?courseResultId=" + resultGuid, toCreate, { withCredentials: true });
  }

  update(toUpdate: TaskGroupResultDto): Observable<TaskGroupResultDto> {
    return this.http
      .put<TaskGroupResultDto>(this.apiUrl + this.controllerUrl + "/update", toUpdate, { withCredentials: true });
  }

  signMany(toUpdate: TaskGroupResultDto[]): Observable<TaskGroupResultDto[]> {
    return this.http
      .put<TaskGroupResultDto[]>(this.apiUrl + this.controllerUrl + "/signmany", toUpdate, { withCredentials: true });
  }

  delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(this.apiUrl + this.controllerUrl + "/delete/?id=" + id, { withCredentials: true });
  }
}
