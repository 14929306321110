// System
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from "@angular/flex-layout";
import { DatePipe } from '@angular/common';
import { AppComponent } from './app.component';

// Material
import { ModuleWithProviders } from "@angular/core";
import { MAT_LABEL_GLOBAL_OPTIONS, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatDividerModule } from "@angular/material/divider";

// Common
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { routing } from './app.routing';
import { ErrorInterceptor } from './common/error.interceptor';
import { JwtInterceptor } from './common/jwt.interceptor';
import { HttpErrorHandler } from './common/http-error-handler';
import { DialogService } from './common/dialog.service';
import { LoaderService } from './common/loader/loader.service';

// Components
import { NavBarComponent } from "./nav-bar/nav-bar.component";
import { FieldCourseComponent } from './field-course-result/field-course-result.component';
import { ResultListComponent } from './field-course-result/result-list.component';
import { ConfirmationDialogComponent } from './common/confirmation-dialog/confirmation-dialog.component';
import { ResultOverviewComponent } from './field-course-result/result-overview/result-overview.component';
import { UserManagerComponent } from './user-manager/user-manager.component';
import { UserSearchPanelComponent } from './user-manager/user-search-panel/user-search-panel.component';
import { CourseManagerComponent } from './course-manager/course-manager.component';
import { CourseUsersComponent } from './course-manager/course-users/course-users.component';
import { AddNewCourseDialogComponent } from './course-manager/add-new-course-dialog/add-new-course-dialog.component';
import { CourseContentComponent } from './course-manager/course-content/course-content.component';
import { TaskGroupDetailsComponent } from './course-manager/task-group-details/task-group-details.component';
import { ResultCommentsComponent } from './field-course-result/result-comments/result-comments.component';
import { StudentProgressComponent } from './student-progress/student-progress.component';
import { ProgressDetailsComponent } from './student-progress/progress-details/progress-details.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { FooterComponent } from './footer/footer.component';
import { CourseResultSettingsComponent } from './field-course-result/course-result-settings/course-result-settings.component';
import { CourseResultDialogComponent } from './field-course-result/course-result-dialog/course-result-dialog.component';
import { LoaderComponent } from './common/loader/loader/loader.component';
import { LoaderInterceptor } from "./common/loader/loader-interceptor";
import { ActiveCoursesComponent } from './course-manager/active-courses/active-courses.component';
import { InactiveCoursesComponent } from './course-manager/inactive-courses/inactive-courses.component';
import { LoginComponent } from './login/login.component';
import { TaskGroupComponent } from './field-course-result/task-group/task-group.component';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    FieldCourseComponent,
    ResultListComponent,
    ResultOverviewComponent,
    UserManagerComponent,
    UserSearchPanelComponent,
    CourseManagerComponent,
    CourseUsersComponent,
    AddNewCourseDialogComponent,
    CourseContentComponent,
    TaskGroupDetailsComponent,
    ResultCommentsComponent,
    StudentProgressComponent,
    ProgressDetailsComponent,
    UserProfileComponent,
    FooterComponent,
    CourseResultSettingsComponent,
    CourseResultDialogComponent,
    LoaderComponent,
    ActiveCoursesComponent,
    InactiveCoursesComponent,
    LoginComponent,
    ConfirmationDialogComponent,
    TaskGroupComponent,
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    routing,
    HttpClientModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatIconModule,
    MatTabsModule,
    BrowserAnimationsModule,
    MatDividerModule,
    MatListModule,
    MatMenuModule,
    MatSnackBarModule,
    MatDialogModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatGridListModule,
    MatTableModule,
    MatSortModule,
    MatExpansionModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatStepperModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatProgressBarModule,
  ],

  providers: [
    DialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    DatePipe,
    { provide: ErrorHandler, useClass: HttpErrorHandler }, // error handler
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, //
    { provide: MAT_DATE_LOCALE, useValue: 'sv-SE' }
    //{
    //  provide: HTTP_INTERCEPTORS,
    //  useClass: JsonInterceptor,
    //  multi: true
    //}
  ],
  bootstrap: [AppComponent],
  entryComponents: [AppComponent, CourseResultDialogComponent, ConfirmationDialogComponent, AddNewCourseDialogComponent]
})

export class AppModule { }
