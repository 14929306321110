import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { CourseResultExtendedDto } from "../../models/CourseResultExtendedDto";
import { CourseResultService } from "../../services/course-result.service";
import { NotificationService } from "../../common/notification.service";
import { Constants } from "../../models/constants";
import { CourseResultState } from "../../models/enum";
import { UserDto } from '../../models/UserDto';

@Component({
    selector: 'course-result-settings',
    templateUrl: './course-result-settings.component.html',
    styleUrls: ['./course-result-settings.component.css']
})
export class CourseResultSettingsComponent implements OnInit {


    public settingsFormGroup = new FormGroup({});

    @Input() courseResult: CourseResultExtendedDto;

    constructor(private formBuilder: FormBuilder, private service: CourseResultService, private notifier: NotificationService, public constants: Constants) { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["courseResult"] && this.courseResult != null) {
            this.generateSettingsGroup();
        }
    }

    public isReadOnly(): boolean {
        return this.courseResult.state === CourseResultState.Inactive.toString() ||
            this.courseResult.state === CourseResultState.Completed.toString();
    }

    public onSupervisorChange() {
        this.saveSettings();
    }

    private saveSettings(): void {
        const updated = Object.assign({}, this.courseResult);
        updated.supervisor =
            this.courseResult.supervisors.find(s => s.id === this.settingsFormGroup.controls["supervisorSelector"].value);
        this.service.update(updated).subscribe(result => {
          if (result) {
                this.courseResult.supervisor = result.supervisor;
                this.generateSettingsGroup();
                this.notifier.showSuccess("Handledare ändrad");
            }
        });
    }

    private generateSettingsGroup() {
        this.settingsFormGroup = new FormGroup({}); // clear
        const group = {};
        group["supervisorSelector"] =
            new FormControl({
                value: null, disabled: false
            });

        this.settingsFormGroup = this.formBuilder.group(group);

        if (this.courseResult.supervisor)
            group["supervisorSelector"].setValue(this.courseResult.supervisor.id);
    }
}
