import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDto } from '../models/UserDto';

import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService {

    constructor(http: HttpClient) {
        super(http);

        this.controllerUrl = '/account';
    }

    getUser(): Observable<UserDto> {

        return this.http.get<UserDto>(this.apiUrl + this.controllerUrl + "/getcurrent", { withCredentials: true });
    }

    getAll(): Observable<UserDto[]> {
        return this.http.get<UserDto[]>(this.apiUrl + this.controllerUrl + "/getusers", { withCredentials: true });
    }

    getActive(): Observable<UserDto[]> {
        return this.http.get<UserDto[]>(this.apiUrl + this.controllerUrl + "/getactiveusers", { withCredentials: true });
    }

    findUser(query: string): Observable<UserDto[]> {

        return this.http.get<UserDto[]>(this.apiUrl + this.controllerUrl + "/finduser/?searchQuery=" + query, { withCredentials: true });
    }

    registerUser(toRegister: UserDto): Observable<UserDto> {

        return this.http.put<UserDto>(this.apiUrl + this.controllerUrl + "/register", toRegister, { withCredentials: true });
    }

    updateUser(updated: UserDto): Observable<UserDto> {
        return this.http.post<UserDto>(this.apiUrl + this.controllerUrl + "/update", updated, { withCredentials: true });
    }

    deleteUser(id: string): Observable<boolean> {
        return this.http.delete<boolean>(this.apiUrl + this.controllerUrl + "/delete/?id=" + id, { withCredentials: true });
    }
}
