<mat-expansion-panel class="mat-elevation-z1" style="margin-top: 5px" hideToggle="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Lägg till användare
    </mat-panel-title>
    <mat-panel-description>
      Sök efter en ny användare
    </mat-panel-description>
    <mat-icon color="accent">search</mat-icon>
  </mat-expansion-panel-header>
  <div>
    <form>
      <mat-form-field style="width: 40%">
        <input matInput #query placeholder="för- och efternamn, endast efternamn, mejladress eller id" (keydown.enter)="search(query.value)">
        <mat-hint>sök på för- och efternamn när ett dubbelnamn förekommer</mat-hint>
        <button mat-button matSuffix mat-icon-button (click)="search(query.value)" color="accent">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </form>
    <mat-selection-list #found [(ngModel)]="selectedUsers">
      <h3 mat-subheader>Hittade användare: {{foundUsers.length}}</h3>
      <mat-list-option *ngFor="let user of foundUsers" [value]="user">
        <mat-grid-list cols="4" rowHeight="30">
          <mat-grid-tile>{{user.ssn}}</mat-grid-tile>
          <mat-grid-tile>{{user.firstName}} {{user.lastName}}</mat-grid-tile>
          <mat-grid-tile>{{user.email}}</mat-grid-tile>
          <mat-grid-tile>{{user.phoneNbr}}</mat-grid-tile>
        </mat-grid-list>
        <mat-divider></mat-divider>
      </mat-list-option>
    </mat-selection-list>
  </div>
  <mat-action-row>
    <button mat-icon-button matBadge="{{found.selectedOptions.selected.length}}" matBadgeColor="accent" [disabled]="found.selectedOptions.selected.length == 0"
            [matMenuTriggerFor]="menu" color="primary" style="margin-right: 10px" matBadgeOverlap="true" [matBadgeHidden]="found.selectedOptions.selected.length == 0">
      <mat-icon>person_add</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let role of availableRoles" (click)="registerUser(role)">{{role}}</button>
    </mat-menu>
  </mat-action-row>
</mat-expansion-panel>



