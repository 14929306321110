<div style="padding: 15px;">
  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="5px" *ngIf="data.result">
      <mat-label mat-dialog-title> {{data.result.taskGroup.name}}</mat-label><mat-icon color="primary">{{constants.getTaskGroupTypeIcon(data.result.taskGroup.groupType)}}</mat-icon>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="5px" *ngIf="!data.result">
      <mat-label mat-dialog-title>Välj typ</mat-label><mat-icon color="primary">create</mat-icon>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px" *ngIf="data.result && data.result.taskGroup.isSupervised && data.result.taskGroup">
      <mat-icon style="color: gray">{{constants.getRoleIcon('Supervisor')}}</mat-icon><mat-label style="color: gray">{{data.result.supervisor?.firstName}} {{data.result.supervisor?.lastName}}</mat-label>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<div style="padding: 15px; ">
  <div mat-dialog-content style="max-height: 60vh" >
    <!--style="overflow-y: hidden"-->
    <!--Type selector-->
    <form [formGroup]="typeSelectForm" *ngIf="!data.result" fxLayout="column" fxLayoutAlign="space-between stretch" fxLayoutGap="10px">
      <mat-form-field>
        <mat-select placeholder="Välj typ av moment" formControlName="typeSelector" [id]="typeSelector" required (selectionChange)="onTypeSelected()">
          <mat-select-trigger>
            {{tooltipConst.getTaskGroupType(this.typeSelectForm.controls["typeSelector"].value)}}
          </mat-select-trigger>
          <mat-option *ngFor="let type of getGroupTypes()" [value]="type">
            <mat-icon>{{constants.getTaskGroupTypeIcon(type)}}</mat-icon>
            {{tooltipConst.getTaskGroupType(type)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Välj ett moment</mat-label>
        <mat-select formControlName="tgSelector" (selectionChange)="onGroupSelected()" required [disabled]="!typeSelectForm.controls['typeSelector'].valid">
          <mat-option *ngFor="let tg of getGroups()" [value]="tg">
            {{tg.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!typeSelectForm.controls['tgSelector'].valid">inget moment valt</mat-error>
      </mat-form-field>
    </form>
    <!--Main form-->
    <form *ngIf="data.result" [formGroup]="dynamicForm" fxLayout="column" fxFill>
      <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="10px">
        <mat-form-field fxFlex="45">
          <input matInput [matDatepicker]="picker1" placeholder="startdatum" formControlName="startDate" [id]="startDate" [matDatepickerFilter]="startDateFilter">
          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field fxFlex="45">
          <input matInput [matDatepicker]="picker2" placeholder="slutdatum" formControlName="endDate" [id]="endDate" [matDatepickerFilter]="endDateFilter">
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
      </div>
      <mat-divider></mat-divider>
      <div>
        <div *ngFor="let result of data.result.taskResults" [ngSwitch]="result.taskItem.taskType">
          <div *ngSwitchCase="'Text'" style="margin: 10px">
            <label style="width: 100%">{{result.originalQuery || result.taskItem.query}}</label>
            <mat-form-field style="width: 100%">
              <mat-label>Skriv ditt svar här</mat-label>
              <textarea matInput #input formControlName="{{result.taskItem.id}}" required style="height: 70px" maxlength="1500"></textarea>
              <mat-hint align="end">{{input.value?.length || 0}}/1500</mat-hint>
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'Amount'" style="margin: 10px">
            <label style="width: 100%">{{result.originalQuery || result.taskItem.query}}</label>
            <mat-form-field>
              <input matInput name="result.taskItem.id" placeholder="Ange antal" formControlName="{{result.taskItem.id}}" required>
              <mat-error>ange i heltal</mat-error>
            </mat-form-field>
          </div>
          <div *ngSwitchCase="'Happiness'" style="margin: 10px;">
            <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-around center">
              <div fxLayout="row" fxLayoutAlign="start center" fxFill>
                <mat-label>{{result.originalQuery || result.taskItem.query}}</mat-label>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" fxFlex="60">
                <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="{{result.taskItem.id}}">
                  <mat-button-toggle value="unhappy" style="color: crimson">
                    <mat-icon>sentiment_very_dissatisfied</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="happy" style="color: gold">
                    <mat-icon>sentiment_satisfied</mat-icon>
                  </mat-button-toggle>
                  <mat-button-toggle value="veryhappy" style="color: green">
                    <mat-icon>sentiment_very_satisfied</mat-icon>
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </div>
          <mat-divider [inset]="true" style="margin-left: 0" *ngIf="result !== data.result.taskResults[data.result.taskResults.length - 1]"></mat-divider>
        </div>
      </div>
    </form>
  </div>
</div>
<mat-divider></mat-divider>
<div fxFill style="padding: 5px">
  <div fxLayout="row" fxLayoutAlign="end center" fxFlex="100" fxLayoutGap="10px">
    <button mat-button mat-dialog-close color="primary">Avbryt</button>
    <div *ngIf="isInRole('Student') && data.result && !isReadOnly()">
      <button mat-button color="primary" *ngIf="data.result.taskGroup !== null && data.result.taskGroup.isSupervised && data.result.supervisor" [disabled]="!dynamicForm.valid" (click)="onSubmit()">Lämna in</button>
      <button mat-button color="accent" *ngIf="!data.result.id" [disabled]="!dynamicForm.valid || !dynamicForm.dirty" (click)="onCreate()">Spara</button>
      <button mat-button cdkFocusInitial *ngIf="data.result.id" color="accent" [disabled]="!dynamicForm.valid || !dynamicForm.dirty" (click)="onSave()">Spara</button>
    </div>
    <div *ngIf="(isInRole('Supervisor') || isInRole('Administrator')) && !isReadOnly()">
      <button mat-button color="warn" (click)="onReject()">Komplettera</button>
      <button mat-button color="accent" (click)="onSign()">Signera</button>
    </div>
  </div>
</div>


