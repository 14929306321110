import { Injectable } from '@angular/core';
import { TaskType, ResultState, TaskItemType, Role } from "./enum";

@Injectable({ providedIn: 'root' })
export class Constants {

    getTaskGroupTypeIcon(type: string): string {
        switch (type) {
            case TaskType.Invalid.toString():
                return "error_outline";
            case TaskType.Exercise.toString():
                return "work_outline";
            case TaskType.Clinical.toString():
                return "transfer_within_a_station";
            case TaskType.Diary.toString():
                return "note";
            case TaskType.Absence.toString():
              return "cancel";
            default:
                return "error_outline";
        }
    }

    getTaskItemTypeIcon(type: string): string {
        switch (type) {
            case TaskItemType.Invalid.toString():
                return "error_outline";
            case TaskItemType.Text.toString():
                return "short_text";
            case TaskItemType.Amount.toString():
                return "question_answer";
            case TaskItemType.Happiness.toString():
                return "sentiment_satisfied_alt";
            default:
                return "error_outline";
        }
    }

    getTaskStateIcon(type: string): string {
        switch (type) {
            case ResultState.Invalid.toString():
                return "error_outline";
            case ResultState.Started.toString():
                return "hourglass_empty";
            case ResultState.Filed.toString():
                return "hourglass_full";
            case ResultState.Rejected.toString():
                return "close";
            case ResultState.Signed.toString():
                return "check";
            default:
                return "error_outline";
        }
    }

    getRoleIcon(type: string): string {
        switch (type) {
            case Role.Invalid.toString():
                return "error_outline";
            case Role.Administrator.toString():
                return "person_outline";
            case Role.Student.toString():
                return "school";
            case Role.Supervisor.toString():
                return "supervisor_account";
            case Role.Teacher.toString():
                return "person";
            default:
                return "error_outline";
        }
    }


}
