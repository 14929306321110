<div>
  <div align="end" style="margin-bottom: 10px; margin-top: 10px">
    <button mat-button color="accent" (click)="openNewCourseDialog()">Lägg till kurs</button>
  </div>
  <div>
    <mat-accordion class="headers-align">
      <!--(opened)="panelOpen = true;" (closed)="panelOpen = false"-->
      <mat-expansion-panel *ngFor="let course of activeCourses" style="margin: 10px;" class="mat-elevation-z1" hideToggle="true">
        <mat-expansion-panel-header [collapsedHeight]="'60px'" [expandedHeight]="'60px'" style="padding: 5px" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="10px">
          <!--<mat-icon fxFlexAlign="start" color="accent" *ngIf="panelOpen == false">expand_more</mat-icon>
          <mat-icon fxFlexAlign="start" color="accent" *ngIf="panelOpen == true">expand_less</mat-icon>-->
          <div fxLayoutAlign="start center" fxLayout="row" fxFlex="60" style="margin-left: 10px">
            <mat-label style="font-size: large;">
              {{course.name}}
            </mat-label>
          </div>
          <div fxLayoutAlign="start center" fxLayout="row" fxFlex="20" fxLayoutGap="5px">
            <mat-icon color="primary">date_range</mat-icon>
            <mat-label>{{course.startDate | date : "yyyy-MM-dd"}} - {{course.endDate | date : "yyyy-MM-dd"}}</mat-label>
          </div>
          <div fxLayoutAlign="end center" fxLayout="row" fxFlex="20" fxLayoutGap="5px" style="margin-right:5px">
            <button mat-icon-button color="primary" aria-label="Redigera" (click)="openEditCourseDialog(course, $event)" matTooltip="{{tooltipConst.edit}}"><mat-icon>edit</mat-icon></button>
            <button mat-icon-button color="accent" aria-label="Resultat" (click)="openStudentResults(course, $event)" matTooltip="Resultat"><mat-icon>school</mat-icon></button>
          </div>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent style="width: 100%">
          <mat-tab-group style="width: 100%">
            <mat-tab label="Registrerade"> <course-users [courseId]="course.id" [users]="users"></course-users></mat-tab>
            <mat-tab label="Kursinnehåll"> <course-content [course]="course"></course-content> </mat-tab>
          </mat-tab-group>
        </ng-template>
        <mat-action-row>
          <button mat-icon-button color="primary" aria-label="Ta bort kurs" (click)="delete(course, $event)" matTooltip="{{tooltipConst.delete}}"><mat-icon>delete</mat-icon></button>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
