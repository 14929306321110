import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CourseResultDto } from '../models/CourseResultDto';
import { Observable } from 'rxjs';
import { TaskGroupResultDto } from "../models/TaskGroupResultDto";
import { CourseResultExtendedDto } from "../models/CourseResultExtendedDto";

@Injectable({ providedIn: 'root' })
export class CourseResultService extends BaseService {

  constructor(http: HttpClient) {
    super(http);

    this.controllerUrl = '/courseresult';
  }

  createUserResult(courseId: string, userIds: string[]): Observable<CourseResultDto[]> {
    return this.http.put<CourseResultDto[]>(this.apiUrl + this.controllerUrl + "/create?courseId=" + courseId, userIds, { withCredentials: true });
  }

  update(courseResult: CourseResultDto): Observable<CourseResultDto> {
    return this.http.post<CourseResultDto>(this.apiUrl + this.controllerUrl + "/update", courseResult, { withCredentials: true });
  }

  evaluate(courseResult: CourseResultDto): Observable<CourseResultDto> {
    return this.http.post<CourseResultDto>(this.apiUrl + this.controllerUrl + "/evaluate", courseResult, { withCredentials: true });
  }

  deleteUserResult(resultGuid: string[]): Observable<string[]> {
    return this.http.post<string[]>(this.apiUrl + this.controllerUrl + "/deletemany", resultGuid, { withCredentials: true });
  }

  getUserResult(resultGuid: string): Observable<CourseResultDto> {
    return this.http.get<CourseResultDto>(this.apiUrl + this.controllerUrl + "/get?id=" + resultGuid, { withCredentials: true });
  }

  // with supervisor
  getExtendedCourseResult(resultGuid: string): Observable<CourseResultExtendedDto> {
    return this.http.get<CourseResultExtendedDto>(this.apiUrl + this.controllerUrl + "/getwithsupervisors?id=" + resultGuid, { withCredentials: true });
  }

  getUserResultsByCourseId(courseId: string): Observable<CourseResultDto[]> {
    return this.http.get<CourseResultDto[]>(this.apiUrl + this.controllerUrl + "/getbycourse?courseId=" + courseId, { withCredentials: true });
  }

  
}
