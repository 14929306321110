import { AuthUserDto } from '../models/AuthUserDto';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {

    constructor(http: HttpClient) {
        super(http);

        this.controllerUrl = '/auth';
    }



    login(loginArgs: AuthUserDto): Observable<AuthUserDto> {
        //const loginArgs: AuthUserDto = { Username: username, Password: password, Token:"" };
        return this.http.post<AuthUserDto>(this.apiUrl + this.controllerUrl + "/login", loginArgs);

            //if (result) {
            //    localStorage.setItem('currentUser', JSON.stringify(result));
            //}
            //return user;
       // });
    }

    logout() {
        localStorage.removeItem('currentUser');
    }
}
