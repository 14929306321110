import { UserDto } from "../models/UserDto";
import { CourseDto } from "../models/CourseDto";
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IStudentProgressMessage {
  currentUser: UserDto;
  course: CourseDto;
}

@Injectable({ providedIn: 'root' })
export class StudentProgressDataService {
  // string message
  private messageSource = new BehaviorSubject<any>(null);

  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(message: IStudentProgressMessage) {
    this.messageSource.next(message);
  }

}
