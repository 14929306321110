import { Component, OnInit } from '@angular/core';
import { UserDto } from "../models/UserDto";
import { UserProfileDataService, IUserProfileMessage } from "./user-profile-data.service";
import { Constants } from "../models/constants";
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AccountService } from "../services/account.service";


@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  public currentUser: UserDto;

  public formGroup = new FormGroup({});

  constructor(private dataService: UserProfileDataService, private constants: Constants, private formBuilder: FormBuilder, private accountService:AccountService) {
    this.dataService.currentMessage.subscribe(message => {
      if (message != null) {
        if (message as IUserProfileMessage) {
          this.currentUser = (message as IUserProfileMessage).currentUser;
          this.generateFormGroup();
        }
      }
    });

  }

  ngOnInit() {
  }

  public onSave(): void {
    const updated = Object.assign({}, this.currentUser);

    updated.organization = this.formGroup.controls["organization"].value;
    this.accountService.updateUser(updated).subscribe(result => {
      if (result) {
        this.currentUser = updated;
        this.dataService.changeMessage({ currentUser:this.currentUser});
      }
    });
  }

  private generateFormGroup() {
    this.formGroup = new FormGroup({}); // clear
    const group = {};
    group["organization"] = new FormControl({ value: this.currentUser.organization || '', disabled: false });
    this.formGroup = this.formBuilder.group(group);
  }
}
