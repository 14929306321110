<div style="margin-top: 10px; padding: 5px" fxLayout="row" fxLayoutGap="20px" fxFill>
  <div fxLayout="column" fxFlex="50">
    <!--style="width: 50%;"-->
    <mat-card style="margin-left: 5px">
      <mat-card-header>
        <mat-card-title>Registrerade</mat-card-title>
        <mat-card-subtitle>Totalt: {{selectableAssigned.length}}</mat-card-subtitle>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <mat-form-field fxLayout="column" style="width: 50%">
          <input matInput (keyup)="applyAssignedFilter($event.target.value)" placeholder="Filter">
          <mat-icon matSuffix style="font-size: smaller" color="primary">filter_list</mat-icon>
        </mat-form-field>
        <table mat-table [dataSource]="assignedDataSource" style="width: 100%; margin-top: 0;" #sortAssigned="matSort" matSort>
          <ng-container matColumnDef="ssn">
            <tr>
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Personnummer</th>
              <td mat-cell *matCellDef="let user">{{user.ssn}} </td>
            </tr>
          </ng-container>
          <ng-container matColumnDef="firstName">
            <tr>
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Förnamn</th>
              <td mat-cell *matCellDef="let user">{{user.firstName}} </td>
            </tr>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <tr>
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Efternamn</th>
              <td mat-cell *matCellDef="let user">{{user.lastName}} </td>
            </tr>
          </ng-container>
          <ng-container matColumnDef="role">
            <tr>
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Roll</th>
              <td mat-cell *matCellDef="let user"><mat-icon class="mat-table-icon" matTooltip="{{tooltipConst.getRole(user.role)}}" >{{constants.getRoleIcon(user.role)}}</mat-icon></td>
            </tr>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let user; columns: displayedColumns;" [ngClass]="{highlighted: user.highlighted}" (click)="selectAssigned(user)"></tr>
        </table>
        <mat-paginator #paginatorAssigned [pageSize]="10" [length]="selectableAssigned.length" showFirstLastButtons></mat-paginator>
      </mat-card-content>
      <mat-card-actions align="end">
        <mat-divider></mat-divider>
        <button mat-button color="primary" style="margin-top: 10px" [disabled]="selectedAssigned.length == 0" (click)="unRegister()"
                [matBadge]="selectedAssigned.length" matBadgePosition="after" matBadgeColor="accent" matBadgeSize="small" matBadgeOverlap="true" [matBadgeHidden]="selectedAssigned.length === 0">
          Avregistrera
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
  <div fxLayout="column" fxFlex="50">
    <mat-card style="margin-right: 5px">
      <mat-card-header>
        <mat-card-title>Tillgängliga</mat-card-title>
        <mat-card-subtitle>Totalt: {{selectableUnassigned.length}}</mat-card-subtitle>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div fxLayout="row">
          <div fxLayout="column" class="fill-remaining-space" style="width: 50%"></div>
          <mat-form-field fxLayout="column" style="width: 50%">
            <input matInput (keyup)="applyUnassignedFilter($event.target.value)" placeholder="Filter">
            <mat-icon matSuffix style="font-size: smaller" color="primary">filter_list</mat-icon>
          </mat-form-field>
        </div>
        <div>
          <table mat-table [dataSource]="unassignedDataSource" style="width: 100%; margin-top: 0;" #sortUnassigned="matSort" matSort>
            <ng-container matColumnDef="ssn">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Personnummer</th>
              <td mat-cell *matCellDef="let user">{{user.ssn}} </td>
            </ng-container>
            <ng-container matColumnDef="firstName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Förnamn</th>
              <td mat-cell *matCellDef="let user">{{user.firstName}} </td>
            </ng-container>
            <ng-container matColumnDef="lastName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Efternamn</th>
              <td mat-cell *matCellDef="let user">{{user.lastName}} </td>
            </ng-container>
            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disableClear>Roll</th>
              <td mat-cell *matCellDef="let user"><mat-icon class="mat-table-icon" matTooltip="{{tooltipConst.getRole(user.role)}}">{{constants.getRoleIcon(user.role)}}</mat-icon></td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let user; columns: displayedColumns;" [ngClass]="{highlighted: user.highlighted}" (click)="selectUnassigned(user)"></tr>
          </table>
          <mat-paginator #paginatorUnassigned [pageSize]="10" [length]="selectableUnassigned.length" showFirstLastButtons></mat-paginator>
        </div>
      </mat-card-content>
      <mat-card-actions align="end">
        <mat-divider></mat-divider>
        <button mat-button color="primary" style="margin-top: 10px" [disabled]="selectedUnassigned.length == 0" (click)="register()"
                [matBadge]="selectedUnassigned.length" matBadgePosition="after" matBadgeColor="accent" matBadgeSize="small" matBadgeOverlap="true" [matBadgeHidden]="selectedUnassigned.length === 0">
          Registrera
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

