import { Injectable } from '@angular/core';

export interface IDuration {
  start: Date,
  end: Date,
}

@Injectable({ providedIn: 'root' })
export class HelperService {

  public static ISO8601_week_no(dt: Date): number {
    var tdt = new Date(dt);
    var dayn = (dt.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    var firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    }

    return 1 + Math.ceil((firstThursday - tdt.valueOf()) / 604800000);
  }

  public static getWeekDuration(dateWithinWeek:Date): IDuration {
    let duration: IDuration = { start: new Date(dateWithinWeek), end: new Date(dateWithinWeek) };
    const d = new Date(dateWithinWeek);
    const n = d.getDay();
    if (n === 0) duration.start.setDate(d.getDate() - 6);
    else
      duration.start.setDate(d.getDate() - (n - 1));
    duration.end.setDate(duration.start.getDate() + 6);
    duration.start.setHours(0, 0, 0, 0);
    duration.end.setHours(23, 59, 59, 0);
    return duration;
  }
}
