import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmDialogModel, DialogType } from "./confirmation-dialog/confirmation-dialog.component";

@Injectable()
export class DialogService {
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>;
    private width = '300px';

    constructor(public dialog: MatDialog) { }

    openConfirmationDialog(data: ConfirmDialogModel, additionalDialogConfigData?: any): MatDialogRef<ConfirmationDialogComponent> {

        if (this.dialogRef) {
            this.dialogRef.close();
        }
        this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: this.width,
            disableClose: true,
            panelClass: 'custom-dialog-container',
            data
        });

        this.dialogRef.afterClosed().subscribe(result => {
        });

        return this.dialogRef;
    }

    openDeleteConfirmationDialog(data: ConfirmDialogModel, additionalDialogConfigData?: any): MatDialogRef<ConfirmationDialogComponent> {

        if (this.dialogRef) {
            this.dialogRef.close();
        }
        if (data != null) {
            this.dialogRef = this.dialog.open(ConfirmationDialogComponent,
                {
                    panelClass: 'custom-dialog-container',
                    width: this.width,
                    disableClose: true,
                    data
                });
        } else {
            this.dialogRef = this.dialog.open(ConfirmationDialogComponent,
                {
                    panelClass: 'custom-dialog-container',
                    width: this.width,
                    disableClose: true,
                    data: new ConfirmDialogModel("Ta bort...",
                        "Är du säker på att du vill ta bort?",
                        DialogType.DeleteConfirmation)
                });
        }

        this.dialogRef.afterClosed().subscribe(result => {
        });

        return this.dialogRef;
    }
}
