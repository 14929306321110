import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

export interface ILoginMessage {
    loggedIn: boolean;
}

@Injectable({ providedIn: 'root' })
export class LoginDataService {
    private messageSource = new BehaviorSubject<any>(null);

    currentMessage = this.messageSource.asObservable();

    constructor() { }

    changeMessage(message: ILoginMessage) {
        this.messageSource.next(message);
    }
}
