import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum DialogType {
  Invalid,
  Confirmation,
  DeleteConfirmation,
  Notification
}

export class ConfirmDialogModel {

  constructor(public title: string, public message: string, public dialogType: DialogType) {
  }
}

@Component({
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  icon: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {


  }

  ngOnInit(): void {
    this.init();
  }

  init() {
    switch (this.data.dialogType) {
      case DialogType.Invalid:
        this.icon = "error_outline";
        break;
      case DialogType.Confirmation:
        this.icon = "check_circle_outline";
        break;
      case DialogType.DeleteConfirmation:
        this.icon = "delete";
        break;
      case DialogType.Notification:
        this.icon = "notification_important";
        break;
      default:
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
