<mat-card class="mat-elevation-z">
  <mat-card-header>
    <div mat-card-avatar><mat-icon color="accent">account_circle</mat-icon></div>
    <mat-card-title>
      Logga in
    </mat-card-title>
    <mat-card-subtitle>
      Fyll i ditt användarnamn och lösenord
    </mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content style="padding-top: 10px; padding-bottom: 10px">
    <form [formGroup]="loginForm" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" style="margin-top:50px">
      <mat-form-field style="width:300px">
        <input matInput placeholder="Användarnamn" formControlName="userName" required>
      </mat-form-field>
      <mat-form-field style="width:300px">
        <input matInput placeholder="Lösenord" [type]="hide ? 'password' : 'text'" formControlName="password" required>
        <!--"hide = !hide"onClick($event)-->
        <button mat-icon-button color="accent" matSuffix (click)="onClick($event)" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
      <div fxLayout="row" fxLayoutAlign="end center" fxFlex="100" style="width:300px">
        <button mat-button color="accent" fxLayoutAlign="center center" (click)="login()" [disabled]="!loginForm.valid" autofocus>Logga in</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
