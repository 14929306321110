import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { TaskGroupDto } from "../models/TaskGroupDto";

@Injectable({ providedIn: 'root' })
export class TaskGroupService extends BaseService {

  constructor(http: HttpClient) {
    super(http);

    this.controllerUrl = '/taskgroup';
  }

  create(courseId: string, created: TaskGroupDto): Observable<TaskGroupDto> {
    return this.http.put<TaskGroupDto>(this.apiUrl + this.controllerUrl + "/create/?courseId=" + courseId, created, { withCredentials: true });
  }

  update(updated: TaskGroupDto): Observable<TaskGroupDto> {
    return this.http.put<TaskGroupDto>(this.apiUrl + this.controllerUrl + "/update", updated, { withCredentials: true });
  }

  delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(this.apiUrl + this.controllerUrl + "/delete/?id=" + id, { withCredentials: true });
  }
}
