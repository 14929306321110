import { UserDto } from "../models/UserDto";
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

export interface IUserProfileMessage {
  currentUser: UserDto;
}

@Injectable({ providedIn: 'root' })
export class UserProfileDataService {
  private messageSource = new BehaviorSubject<any>(null);

  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(message: IUserProfileMessage) {
    this.messageSource.next(message);
  }
}
