
<div fxFill style="padding-left: 5px; padding-right: 5px">
  <task-group *ngIf="taskGroupActive" [data]="taskGroupData" (messageEvent)="receiveResult($event)"></task-group>
  <div *ngIf="!taskGroupActive" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center start" >
    <course-result-settings *ngIf="isInRole('Student')" [courseResult]="fcResult" fxFill></course-result-settings>
    <mat-card fxFill>
      <mat-card-content>
        <div fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="10px" *ngIf="isInRole('Student')">
            <mat-form-field style="width: 30%" fxHide fxShow.gt-sm>
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter" [disabled]="weeklyEnabled">
              <mat-icon matSuffix style="font-size: medium" color="primary">filter_list</mat-icon>
            </mat-form-field>
            <mat-button-toggle-group #group="matButtonToggleGroup" (change)="onWeeklyFilter(group.value)" [value]="selectedView" fxHide fxShow.gt-xs>
              <mat-button-toggle value="all">Alla</mat-button-toggle>
              <mat-button-toggle value="weekly">Veckovis</mat-button-toggle>
            </mat-button-toggle-group>
            <div fxLayout="row">
              <button mat-icon-button [disabled]="!weeklyEnabled" color="accent" (click)="prevWeek()">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>
              <div fxLayout="column" fxLayoutAlign="space-between center">
                <mat-label style="font-size: x-small;">vecka</mat-label>
                <mat-label style="font-weight: bold">{{currentWeek}}</mat-label>
                <mat-label style="font-size: x-small">{{this.duration.start | date : "MM/dd"}} - {{this.duration.end | date : "MM/dd"}}</mat-label>
              </div>
              <button mat-icon-button [disabled]="!weeklyEnabled" color="accent" (click)="nextWeek()">
                <mat-icon>keyboard_arrow_right</mat-icon>
              </button>
            </div>
            <div>
              <button mat-flat-button color="accent" (click)="onAddClicked()" [disabled]="isReadOnly()" fxHide fxShow.lt-md>Nytt</button>
              <button mat-flat-button color="accent" (click)="onAddClicked()" [disabled]="isReadOnly()" fxHide fxShow.gt-sm>Nytt moment</button>
            </div>
          </div>
          <div fxLayout="row" fxLayoutAlign="end center" *ngIf="isInRole('Administrator') || isInRole('Supervisor')">
            <button mat-button color="accent" (click)="signWeekly()">Klarmarkera alla</button>
          </div>
          <table mat-table [dataSource]="resultDataSource" matSort style="width: 100%; overflow: auto" #sort="matSort" (matSortChange)="sortColumn($event)">
            <ng-container matColumnDef="type">
              <tr>
                <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Typ</th>
                <td mat-cell *matCellDef="let result" fxHide fxShow.gt-sm><mat-icon class="mat-table-icon" matTooltip={{tooltipConst.getTaskGroupType(result.type)}}>{{constants.getTaskGroupTypeIcon(result.type)}}</mat-icon></td>
              </tr>
            </ng-container>
            <ng-container matColumnDef="name">
              <tr style="width: 50%; overflow-wrap: break-word; word-wrap: break-word">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Namn</th>
                <td mat-cell *matCellDef="let result"> {{result.name}} </td>
              </tr>
            </ng-container>
            <ng-container matColumnDef="start">
              <tr>
                <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Start</th>
                <td mat-cell *matCellDef="let result" fxHide fxShow.gt-sm>
                  <div fxLayout="row" fxLayoutAlign="start end">
                    <div fxLayout="column" fxLayoutAlign="space-around center">
                      {{result.startDate | date : "yyyy-MM-dd"}}
                      <label style="font-size: smaller">({{getWeek(result.startDate)}})</label>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-container matColumnDef="end">
              <tr>
                <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Slut</th>
                <td mat-cell *matCellDef="let result" fxHide fxShow.gt-sm>
                  <div fxLayout="row" fxLayoutAlign="start end">
                    <div fxLayout="column" fxLayoutAlign="space-around center">
                      {{result.endDate | date : "yyyy-MM-dd"}}
                      <label style="font-size: smaller" fxLayoutAlign="center center">({{getWeek(result.endDate)}})</label>
                    </div>
                  </div>
                </td>
              </tr>
            </ng-container>
            <ng-container matColumnDef="state">
              <tr>
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let result"><mat-icon class="mat-table-icon" matTooltip={{tooltipConst.getTaskGroupState(result.state)}}>{{constants.getTaskStateIcon(result.state.toString())}}</mat-icon></td>
              </tr>
            </ng-container>
            <ng-container matColumnDef="remove">
              <tr>
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let result"><button mat-icon-button (click)="deleteResult(result, $event)" matTooltip={{tooltipConst.delete}} [disabled]="!canDelete(result) || isReadOnly()" color="primary"><mat-icon>delete</mat-icon></button></td>
              </tr>
            </ng-container>
            <ng-container matColumnDef="reject">
              <tr>
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let result"><button mat-icon-button (click)="rejectResult(result, $event)" matTooltip={{tooltipConst.reject}} [disabled]="isReadOnly() || !canSignOrReject(result.payload)" color="primary"><mat-icon>{{constants.getTaskStateIcon('Rejected')}}</mat-icon></button></td>
              </tr>
            </ng-container>
            <ng-container matColumnDef="sign">
              <tr>
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let result"><button mat-icon-button (click)="signResult(result, $event)" matTooltip={{tooltipConst.sign}} [disabled]="isReadOnly() || !canSignOrReject(result.payload)" color="accent"><mat-icon>{{constants.getTaskStateIcon('Signed')}}</mat-icon></button></td>
              </tr>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let result; columns: displayedColumns;" [ngClass]="{highlighted: result.highlighted}" (click)="onTaskResultClick(result)"></tr>
          </table>
          <mat-paginator #paginator [pageSizeOptions]="[15, 30, 50]" [length]="resultDataSource.filteredData.length" showFirstLastButtons></mat-paginator>
        </div>
      </mat-card-content>
      <mat-divider ></mat-divider>
      <div fxHide fxShow.xs fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" style="margin-top: 30px"><mat-icon color="accent">screen_rotation</mat-icon><!--<mat-label style="font-size: small; font-weight: bold; color: #443677">för kommentarer</mat-label>--></div>
      <mat-card-actions fxHide fxShow.gt-xs>
        <result-comments [weeklyEnabled]="weeklyEnabled" [currentWeek]="currentWeek" [duration]="duration" [courseResult]="fcResult" [currentUser]="currentUser"></result-comments>
      </mat-card-actions>
    </mat-card>
    </div>
  </div>
