import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AddNewCourseDialogComponent } from '../add-new-course-dialog/add-new-course-dialog.component';
import { AccountService } from '../../services/account.service';
import { UserDto } from '../../models/UserDto';
import { CourseDto } from "../../models/CourseDto";
import { CourseService } from "../../services/course.service";
import { NotificationService } from "../../common/notification.service";
import { StudentProgressDataService } from "../../student-progress/student-progress-data.service";
import { CourseManagerDataService, ICourseManagerMessage } from "../course-manager-data.service";
import { DialogService } from "../../common/dialog.service";
import { Router } from '@angular/router';
import { MatDialog } from "@angular/material/dialog";
import { CourseState } from '../../models/enum';
import Inactive = CourseState.Inactive;
import { TooltipConstants } from "../../common/tooltip.constants";

@Component({
    selector: 'active-courses',
    templateUrl: './active-courses.component.html',
    styleUrls: ['./active-courses.component.scss']
})
export class ActiveCoursesComponent /*implements OnInit*/ {

    //public panelOpen = false;

    @Input() currentUser: UserDto;

    @Input() users: UserDto[];

    @Input() activeCourses: CourseDto[] = [];

    @Input() courses: CourseDto[] = [];

    @Output() refreshTrigger = new EventEmitter<any>();

    constructor(private accountService: AccountService, private courseService: CourseService, private dialog: MatDialog, private notifier: NotificationService,
        private dialogService: DialogService, private router: Router, private studentProgressDataService: StudentProgressDataService, public tooltipConst: TooltipConstants) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes["activeCourses"] && this.activeCourses) {
            this.activeCourses.sort((a: CourseDto, b: CourseDto) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());
      }
    }

    public openStudentResults(course: CourseDto, event: any): void {
        event.stopPropagation();
        this.router.navigate(['student-progress']);
        this.studentProgressDataService.changeMessage({ course: course, currentUser: this.currentUser });
    }

    public openNewCourseDialog(): void {
        const dialogRef = this.dialog.open(AddNewCourseDialogComponent,
            {
                width: "400px",
                data: { courses: this.activeCourses },
                disableClose: true
            });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.courses.push(result);
                this.notifier.showSuccess(result.name + " skapad");
                this.refreshTrigger.emit(result);
            }
        });
    }

    public openEditCourseDialog(course: CourseDto, event: any): void {
        event.stopPropagation();
        const dialogRef = this.dialog.open(AddNewCourseDialogComponent,
            {
                width: "400px",
                data: { courses: this.activeCourses, created: course },
                disableClose: true
            });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.notifier.showSuccess(result.name + " uppdaterad");
                const index = this.courses.indexOf(course);
                if (index > -1) {
                    this.courses.splice(index, 1, result);
                    this.refreshTrigger.emit(result);
                }
            }
        });
    }

    public delete(course: CourseDto, event: any) {
        event.stopPropagation();
        const dialogRef = this.dialogService.openDeleteConfirmationDialog(null);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.courseService.delete(course.id).subscribe(result => {
                    if (result) {
                        this.notifier.showSuccess(course.name + " borttagen");
                        const index = this.courses.indexOf(course);
                        if (index > -1) {
                            this.courses.splice(index, 1);
                            this.refreshTrigger.emit(result);
                        }
                    }
                });
            }
        });
    }
}
