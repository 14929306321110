// string enum
export enum ResultState {
    Invalid = "Invalid",

    Started = "Started",

    Filed = "Filed",

    Signed = "Signed",

    Rejected = "Rejected",

    //NotAttended = "NotAttended"
}

export enum TaskType {
    Invalid = "Invalid",

    // Faculty specific
    Clinical = "Clinical",

    Exercise = "Exercise",

    // Student notes
    Diary = "Diary",

    Absence = "Absence"
}

export enum TaskItemType {
    Invalid = "Invalid",

    Text = "Text",

    Amount = "Amount",

    Happiness = "Happiness"
}

export enum Role {

    Invalid = "Invalid",

    Student = "Student",

    Supervisor = "Supervisor",

    Teacher = "Teacher",

    Administrator = "Administrator",
}

export enum CourseResultState {

    Invalid = "Invalid",

    Active = "Active",

    Inactive = "Inactive",

    Completed = "Completed",
}

export enum CourseState {

    Invalid = "Invalid",

    Active = "Active",

    Inactive = "Inactive",
}
