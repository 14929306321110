<mat-toolbar color="primary">
  <img src="assets/logo-mau-white.svg" width="135" height="45"/>
  <span class="fill-remaining-space"></span>
  <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="user">
    <button mat-stroked-button color="white" style="font-size: small; width: auto; margin-right: 10px; border-color: white" matTooltip="Profil" (click)="onProfileClick()">
      <mat-label fxHide fxShow.gt-sm style="margin-right: 5px">{{user.firstName}} {{user.lastName}}</mat-label>
      <mat-icon color="white">account_circle</mat-icon>
    </button>
    <!--<mat-divider [vertical]="true" style="height:32px; background:white"></mat-divider>-->
    <button mat-icon-button matTooltip="Kurser" *ngIf="user.role !== 'Administrator'" [matMenuTriggerFor]="menu"><mat-icon color="white">school</mat-icon></button>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let cr of user.courseResults">
        <div>
          <button mat-menu-item (click)="onCourseResultClick(cr)">{{ cr.course.name }}</button>
        </div>
      </ng-container>
    </mat-menu>
    <!--Admin-->
    <button mat-icon-button matTooltip="Kurshantering" *ngIf="user.role === 'Administrator'" (click)="onCourseManagerClick()"><mat-icon color="white">settings</mat-icon></button>
    <button mat-icon-button matTooltip="Användare" *ngIf="user.role === 'Administrator'" (click)="onUserManagerClick()"><mat-icon color="white">supervised_user_circle</mat-icon></button>
  </div>
  <!--<button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="menuItems">
    <mat-icon style="color:white">menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu" >
    <ng-container *ngFor="let menuItem of menuItems">
      <div *ngIf="menuItem.isSubMenu">
        <button mat-menu-item [matMenuTriggerFor]="sub_menu" ><mat-icon *ngIf="menuItem.icon" >{{menuItem.icon}}</mat-icon>{{ menuItem.title }}</button>
        <mat-menu #sub_menu="matMenu">
          <button *ngFor="let subItem of menuItem.subMenu" (click)="subItem.onClick(subItem.arg)" mat-menu-item > <mat-icon *ngIf="subItem.icon" ></mat-icon>{{ subItem.title }}</button>
        </mat-menu>
      </div>
      <div *ngIf="!menuItem.isSubMenu">
        <button mat-menu-item (click)="menuItem.onClick()" ><mat-icon *ngIf="menuItem.icon">{{menuItem.icon}}</mat-icon>{{ menuItem.title }}</button>
      </div>
    </ng-container>
  </mat-menu>-->
</mat-toolbar>
