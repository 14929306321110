import { Component, OnInit, Input, SimpleChanges, ViewChild, Query } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HelperService, IDuration } from '../../common/helper.service';
import { CommentService } from '../../services/comment.service';
import { CommentDto } from '../../models/CommentDto';
import { CourseResultDto } from "../../models/CourseResultDto";
import { Constants } from "../../models/constants";
import { UserDto } from "../../models/UserDto";
import { NotificationService } from "../../common/notification.service";
import { CourseResultState, Role } from "../../models/enum";
import Student = Role.Student;
import { MatList } from "@angular/material/list";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

import { TooltipConstants } from '../../common/tooltip.constants';

@Component({
  selector: 'result-comments',
  templateUrl: './result-comments.component.html',
  styleUrls: ['./result-comments.component.css']
})
export class ResultCommentsComponent implements OnInit {

  private currentDate: Date;

  public currentComments: CommentDto[] = [];

  public comment: string;

  public commentForm: FormGroup = new FormGroup({});

  public commentsDataSource: MatTableDataSource<CommentDto> = new MatTableDataSource([]);

  public displayedColumns: string[] = ["week", "read", "content", "leftBy", "delete"];

  @Input() weeklyEnabled: boolean = false;
  @Input() currentWeek: number;
  @Input() courseResult: CourseResultDto;
  @Input() currentUser: UserDto;
  @Input() duration: IDuration;

  @ViewChild("paginator", { static: true }) paginator: MatPaginator;

  constructor(private helper: HelperService, private formBuilder: FormBuilder, private commentService: CommentService, public constants: Constants, private notifier: NotificationService) {
    this.buildFormGroup();
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["weeklyEnabled"]) {
      if (!this.weeklyEnabled) { this.currentWeek = HelperService.ISO8601_week_no(new Date); this.currentDate = new Date() }
      else { this.currentDate = new Date(this.duration.start) }
    }
    if (changes["duration"] && this.duration) {
      this.currentDate = new Date(this.duration.start);
      this.buildDataSource();
    }
    if (changes["courseResult"] && this.courseResult !== null) {
      this.sort();
    }
    if (changes["currentWeek"] && this.duration) {
      this.buildDataSource();
    }
    if (changes["weeklyEnabled"]) {
      this.buildDataSource();
    }
  }

  public getUnread(): number {
    if (!this.currentUser || !this.duration) return 0/*"0"*/;
    // student role
    if (this.currentUser.id === this.courseResult.user.id && this.currentUser.role === Student.toString()) {
      if (this.weeklyEnabled)
        return this.courseResult.comments.filter(c => !c.isRead &&
          c.leftBy.id !== this.currentUser.id &&
          new Date(c.date).getTime() <= new Date(this.duration.end).getTime() &&
          new Date(c.date).getTime() >= new Date(this.duration.start).getTime()).length/*.toString()*/;
      else
        return this.courseResult.comments.filter(c => !c.isRead && c.leftBy.id !== this.currentUser.id).length
                    /*.toString()*/;
    } else {
      if (this.weeklyEnabled)
        return this.courseResult.comments.filter(c => !c.isRead && c.leftBy.id === this.courseResult.user.id &&
          new Date(c.date).getTime() <= new Date(this.duration.end).getTime() &&
          new Date(c.date).getTime() >= new Date(this.duration.start).getTime()).length
                    /*.toString()*/;
      else
        return this.courseResult.comments.filter(c => !c.isRead && c.leftBy.id === this.courseResult.user.id).length
                    /*.toString()*/;
    }
  }

  public isReadOnly(): boolean {
    if (!this.courseResult) return true;
    return this.courseResult.state === CourseResultState.Inactive.toString() ||
      this.courseResult.state === CourseResultState.Completed.toString();
  }

  public onSubmit(): void {
    const commentDate = this.weeklyEnabled ? new Date(this.duration.start) : new Date();
    const comment: CommentDto = { id: null, isRead: false, text: this.commentForm.controls["comment"].value, date: commentDate, leftBy: null }
    this.commentService.create(this.courseResult.id, comment).subscribe(result => {
      if (result) {
        this.commentForm.reset();
        result.date = new Date(result.date.toString());
        this.courseResult.comments.push(result);
        this.sort();
        this.buildDataSource();
      }
    });
  }

  public onDelete(comment: CommentDto, event): void {
    event.stopPropagation();
    this.commentService.delete(comment.id).subscribe(result => {
      if (result) {
        const index = this.courseResult.comments.findIndex(c => c.id === comment.id);
        if (index > -1) {
          this.courseResult.comments.splice(index, 1);
          this.buildDataSource();
          this.notifier.showSuccess("Kommentar borttagen");
        }
      }
    });
  }

  public onCancel(): void {
    this.buildFormGroup();
  }

  public readComment(comment: CommentDto) {
    if (comment.isRead || comment.leftBy.id === this.currentUser.id) return;
    const copy = Object.assign({}, comment);
    copy.isRead = true;
    copy.date = new Date(copy.date);
    this.commentService.update(copy).subscribe(result => {
      if (result) {
        const index = this.courseResult.comments.findIndex(c => c.id === result.id);
        if (index !== -1) {
          this.courseResult.comments[index] = result;
          this.buildDataSource();
        }
      }
    });
  }

  public getWeekNbr(date: Date): string {
    return HelperService.ISO8601_week_no(new Date(date)).toString();
  }

  private buildDataSource(): void {
    const toAdd: CommentDto[] = [];
    for (let comment of this.courseResult.comments) {
      if ((this.getWeekNbr(comment.date) === this.currentWeek.toString() && this.weeklyEnabled) || !this.weeklyEnabled) {
        toAdd.push(comment);
      }
    }

    this.commentsDataSource = new MatTableDataSource(toAdd.sort((x, y) => (x.isRead === y.isRead) ? 0 : x ? -1 : 1));
    this.commentsDataSource.paginator = this.paginator;
  }

  private buildFormGroup(): void {
    this.commentForm = new FormGroup({});
    const group = {};
    group["comment"] = new FormControl("");
    this.commentForm = this.formBuilder.group(group);
  }

  private sort(): void {
    if (!this.courseResult) return;
    this.courseResult.comments.sort((a: CommentDto, b: CommentDto) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
  }
}
