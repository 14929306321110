import { Injectable, NgZone, Component } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    public snackBar: MatSnackBar,
    private zone: NgZone) { }

  showSuccess(message: string): void {
    //const config = new MatSnackBarConfig();
    //config.panelClass = ['success-snackbar'];

    // Had an issue with the snackbar being ran outside of angular's zone.
    this.zone.run(() => {
      this.snackBar.open(message, '', { duration: 3000, panelClass: ['success-snackbar']});
    });
  }

  showError(message: string): void {
    this.zone.run(() => {
      // The second parameter is the text in the button. 
      // In the third, we send in the css class for the snack bar.
      this.snackBar.open(message, 'stäng', { panelClass: ['error-snackbar'] });
    });
  }
}
