import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommentDto } from '../models/CommentDto';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommentService extends BaseService {

  constructor(http: HttpClient) {
    super(http);

    this.controllerUrl = '/comment';
  }

  public create(courseResultId: string, comment: CommentDto): Observable<CommentDto> {

    return this.http.post<CommentDto>(this.apiUrl + this.controllerUrl + "/create/?courseResultId=" + courseResultId, comment, { withCredentials: true });
  }

  public update(updated: CommentDto): Observable<CommentDto> {
    return this.http.put<CommentDto>(this.apiUrl + this.controllerUrl + "/update", updated, { withCredentials: true });
  }

  public delete(id:string): Observable<boolean> {

    return this.http.delete<boolean>(this.apiUrl + this.controllerUrl + "/delete/?id="+ id, { withCredentials: true });
  }
}
