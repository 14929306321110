   import { TaskGroupDto } from './TaskGroupDto';
import { UserDto } from './UserDto';
import { TaskItemResultDto } from './TaskItemResultDto';

export class TaskGroupResultDto   {
	public id: string;
	public taskGroup: TaskGroupDto;
	public state: string;
	public startDate: Date;
	public endDate: Date;
	public supervisor: UserDto;
	public taskResults: TaskItemResultDto[];
}
