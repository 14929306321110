import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CourseDto } from '../models/CourseDto';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class CourseService extends BaseService {

  constructor(http: HttpClient) {
    super(http);

    this.controllerUrl = '/course';
  }

  getAll(): Observable<CourseDto[]> {
    return this.http.get<CourseDto[]>(this.apiUrl + this.controllerUrl + "/get", { withCredentials: true });
  }

  getActive(): Observable<CourseDto[]> {
    return this.http.get<CourseDto[]>(this.apiUrl + this.controllerUrl + "/getactive", { withCredentials: true });
  }

  create(copyId: string, created: CourseDto): Observable<CourseDto> {
    return this.http.put<CourseDto>(this.apiUrl + this.controllerUrl + "/create/?copyId=" + copyId, created, { withCredentials: true });
  }

  update(updated: CourseDto): Observable<CourseDto> {
    return this.http.post<CourseDto>(this.apiUrl + this.controllerUrl + "/update", updated, { withCredentials: true });
  }

  delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(this.apiUrl + this.controllerUrl + "/delete/?id=" + id, { withCredentials: true });
  }
}
