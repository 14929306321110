<mat-expansion-panel class="mat-elevation-z1" hideToggle="true" style="width: 100%;" [disabled]="isReadOnly()">
  <mat-expansion-panel-header>
    <mat-panel-title fxHide fxShow.gt-sm>
      Inställningar
    </mat-panel-title>
    <mat-panel-description>
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
        <mat-icon>{{constants.getRoleIcon('Supervisor')}}</mat-icon><mat-label *ngIf="courseResult.supervisor">{{courseResult.supervisor.firstName}} {{courseResult.supervisor.lastName}}</mat-label>
        <mat-label *ngIf="!courseResult.supervisor">välj handledare</mat-label>
      </div>
    </mat-panel-description>

    <mat-icon color="accent">settings</mat-icon>

  </mat-expansion-panel-header>
  <div fxLayout="row" fxLayoutGap="32px" fxLayoutAlign="start center">
    <form [formGroup]="settingsFormGroup">
      <div fxFlex="60">
        <mat-form-field>
          <!--[id]="supervisorSelector"-->
          <mat-select placeholder="Välj handledare" formControlName="supervisorSelector" (selectionChange)="onSupervisorChange()">
            <mat-option *ngFor="let supervisor of courseResult.supervisors" [value]="supervisor.id">
              {{supervisor.firstName}} {{supervisor.lastName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-expansion-panel>
