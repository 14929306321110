import { Component, OnInit, Input, SimpleChanges, Output, ViewChild, EventEmitter } from '@angular/core';
import { ResultListComponent } from '../result-list.component';
import { ResultState, TaskType, TaskItemType, Role, CourseResultState } from "../../models/enum";
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTab } from '@angular/material/tabs';
import { TaskGroupResultDto } from "../../models/TaskGroupResultDto";
import { Constants } from '../../models/constants';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CourseResultService } from "../../services/course-result.service";
import { CourseResultDto } from "../../models/CourseResultDto";
import { UserDto } from "../../models/UserDto";
import { HelperService } from '../../common/helper.service';
import { NotificationService } from '../../common/notification.service';
import { MatPaginator } from "@angular/material/paginator";
import { TooltipConstants } from "../../common/tooltip.constants";
import { ExcelExportService } from "src/app/services/excel-export.service";
import Absence = TaskType.Absence;
import Signed = ResultState.Signed;

interface ITypeValue {
  type: string;
  amount: number;
}

interface IStateValue {
  state: string;
  amount: number;
}

@Component({
  selector: 'result-overview',
  templateUrl: './result-overview.component.html',
  styleUrls: ["../field-course-result.component.css"]
})
export class ResultOverviewComponent {

  public displayedColumns: string[] = ["type", "amount"];

  public displayedStatusColumns: string[] = ["state", "amount"];

  public evaluation: FormGroup = this.fb.group({ text: '' });

  public typeValues: ITypeValue[] = [];

  //public stateValues: IStateValue[] = [];

  public stateValues: { [type: string]: number; } = {};

  public amountOfWeeks = 0;

  public absentDays = 0;

  @Input() fcResult: CourseResultDto;

  @Input() currentTab: MatTab;

  @Input() currentUser: UserDto;

  @Output() refreshResult: EventEmitter<any> = new EventEmitter();

  @ViewChild("paginator", { static: true }) paginator: MatPaginator;

  constructor(public constants: Constants, private fb: FormBuilder, private service: CourseResultService, private notifier: NotificationService, public tooltipConst: TooltipConstants, private exportService: ExcelExportService) {

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes["fcResult"] && this.fcResult != null) {
      this.getTypeStats();
      this.getStateStats();
      this.getTotalAmountOfWeeks();
      this.buildFormGroup();
    }
    if (changes["currentTab"] && this.currentTab != null && this.fcResult != null && this.currentTab.textLabel === "Översikt") {
      this.getTypeStats();
      this.getStateStats();
      this.getTotalAmountOfWeeks();
      this.buildFormGroup();
    }
  }

  public isReadOnly(): boolean {
    if (!this.fcResult) return true;
    return this.fcResult.state === CourseResultState.Completed.toString() || this.fcResult.state === CourseResultState.Inactive.toString();
  }

  public canEvaluate(): boolean {
    if (!this.currentUser) return false;
    return this.currentUser.role === Role.Administrator.toString() || this.currentUser.role === Role.Teacher.toString() && this.fcResult.state !== CourseResultState.Inactive.toString();
  }

  public toggleActiveState(): void {
    if (this.fcResult.state === CourseResultState.Completed.toString() || this.fcResult.state === CourseResultState.Invalid.toString()) return;

    const updated = Object.assign({}, this.fcResult);
    if (this.fcResult.state === CourseResultState.Inactive.toString())
      updated.state = CourseResultState.Active.toString();
    else if (this.fcResult.state === CourseResultState.Active.toString())
      updated.state = CourseResultState.Inactive.toString();
    this.service.evaluate(updated).subscribe(result => {
      if (result) {
        this.fcResult.state = result.state;
        this.fcResult.evaluation = result.evaluation;
        this.buildFormGroup();
        this.refreshResult.emit(this.fcResult);
      }
    });
  }

  public unLock(): void {
    const updated = Object.assign({}, this.fcResult);
    updated.state = CourseResultState.Active.toString();
    this.service.evaluate(updated).subscribe(result => {
      if (result) {
        this.fcResult.state = result.state;
        this.fcResult.evaluation = result.evaluation;
        this.buildFormGroup();
        this.notifier.showSuccess("Kursen aktiverad");
        this.refreshResult.emit(this.fcResult);
      }
    });
  }

  public onSave(): void {
    const updated = Object.assign({}, this.fcResult);
    updated.evaluation = this.evaluation.controls["text"].value;
    this.service.evaluate(updated).subscribe(result => {
      if (result) {
        this.fcResult.state = result.state;
        this.fcResult.evaluation = result.evaluation;
        this.buildFormGroup();
        this.notifier.showSuccess("Omdömme sparat");
      }
    });
  }

  public onCompleted(): void {
    const updated = Object.assign({}, this.fcResult);
    updated.evaluation = this.evaluation.controls["text"].value;
    updated.state = CourseResultState.Completed.toString();
    this.service.evaluate(updated).subscribe(result => {
      if (result) {
        this.fcResult.state = result.state;
        this.fcResult.evaluation = result.evaluation;
        this.buildFormGroup();
        this.notifier.showSuccess("Kursen avslutad");
        this.refreshResult.emit(this.fcResult);
      }
    });
  }

  public getTotalByType(): number {
    let sum: number = 0;
    for (let value of this.typeValues)
      sum += value.amount;
    return sum;
  }

  public excelExport(): void {
    if (this.fcResult)
      this.exportService.generate(this.fcResult);
  }

  public getTotalAmountOfWeeks() {
    const weekSet = new Set<number>();
    for (let result of this.fcResult.taskGroupResults) {
      const next = new Date(result.startDate);
      do {
        const startWeek = HelperService.ISO8601_week_no(next);
        weekSet.add(startWeek);
        next.setDate(next.getDate() + 1);
      } while (next.getTime() <= new Date(result.endDate).getTime())
    }
    this.amountOfWeeks = weekSet.size;
  }

  private getTypeStats() {
    this.typeValues = [];
    for (let result of this.fcResult.taskGroupResults) {
      if ((result.taskGroup.groupType === TaskType.Clinical.toString() || result.taskGroup.groupType === TaskType.Exercise.toString()) && result.state === Signed.toString()) {
        const existing = this.typeValues.find(f => f.type === result.taskGroup.name);
        const amountTasks = result.taskResults.filter(tr => tr.taskItem.taskType === TaskItemType.Amount.toString());
        let totalAmount = 0;
        for (let task of amountTasks) {
          totalAmount += +task.answer;
        }
        if (existing != null)
          existing.amount += totalAmount;
        else
          this.typeValues.push({ type: result.taskGroup.name, amount: totalAmount });
      }
    }
  }

  private getStateStats() {
    // this.stateValues = [];
    this.absentDays = 0;
    this.stateValues = {};

    for (let result of this.fcResult.taskGroupResults) {
      if (result.state === ResultState.Invalid.toString()) continue;
      if (result.taskGroup.groupType === Absence.toString()) {
        this.absentDays += this.calculateAbsence(result.startDate, result.endDate);
        continue;
      }
      if (result.state in this.stateValues)
        this.stateValues[result.state]++;
      else
        this.stateValues[result.state] = 1;

    }
    this.stateValues[Absence.toString()] = this.absentDays;

    //for (let result of this.fcResult.taskGroupResults) {
    //    if (result.state === ResultState.Invalid.toString()) continue;
    //    if (result.taskGroup.groupType === Absence.toString()) {
    //      this.absentDays+= this.calculateAbsence(result.startDate, result.endDate);
    //        continue;
    //    }
    //    const existing = this.stateValues.find(f => f.state === result.state);
    //    if (existing != null) {
    //        existing.amount++;
    //    } else
    //        this.stateValues.push({ state: result.state, amount: 1 });
    //}
  }

  private buildFormGroup(): void {
    this.evaluation = this.fb.group({
      text: { value: this.fcResult.evaluation, disabled: this.isReadOnly() }
    });
  }

  private calculateAbsence(start: Date, end: Date): number {
    let counter = 0;
    let current = new Date(start);
    let last = new Date(end);
    last.setHours(23, 59, 0, 0);
    while (current.getTime() <= last.getTime()) {
      if (current.getDay() < 6 && current.getDay() > 0)
        counter++;

      current = new Date(current.getTime() + (1000 * 60 * 60 * 24));
    }
    return counter;
  }
}
