   import { CourseDto } from './CourseDto';
import { UserDto } from './UserDto';
import { TaskGroupResultDto } from './TaskGroupResultDto';
import { CommentDto } from './CommentDto';

export class CourseResultDto   {
	public id: string;
	public state: string;
	public course: CourseDto;
	public user: UserDto;
  public supervisor: UserDto;
  public evaluation: string;
	public taskGroupResults: TaskGroupResultDto[];
	public comments: CommentDto[];
}
