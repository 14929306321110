<div fxFlex="100" style="margin-top: 10px" fxLayout="row" fxLayoutGap="15px">
  <div fxLayout="column" fxFlex="60">
    <mat-card style="margin-left: 5px; margin-bottom: 5px">
      <mat-card-header>
        <mat-card-title>Moment</mat-card-title>
        <mat-card-subtitle>
          <div *ngIf="course">
            Antal: {{course.taskGroups?.length.toString()}}
          </div>
        </mat-card-subtitle>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content >
        <mat-form-field style="width: 45%; margin-top: 10px">
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
          <mat-icon matSuffix style="font-size: medium" color="accent">filter_list</mat-icon>
        </mat-form-field>
        <div style="margin-left: 5px; margin-right: 5px;">
          <table mat-table [dataSource]="selectableList" style="width: 100%;" matSort #sort="matSort">
            <ng-container matColumnDef="groupType">
              <tr>
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
                <td mat-cell *matCellDef="let item"><mat-icon class="mat-table-icon" matTooltip="{{tooltipConst.getTaskGroupType(item.payload.groupType)}}">{{constants.getTaskGroupTypeIcon(item.payload.groupType)}}</mat-icon> </td>
              </tr>
            </ng-container>
            <ng-container matColumnDef="name">
              <tr>
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Namn</th>
                <td mat-cell *matCellDef="let item">{{item.payload.name}}</td>
              </tr>
            </ng-container>
            <ng-container matColumnDef="isMandatory">
              <tr>
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Obligatorisk</th>
                <td mat-cell *matCellDef="let item"><mat-icon *ngIf="item.payload.isMandatory" class="mat-table-icon">check</mat-icon></td>
              </tr>
            </ng-container>
            <ng-container matColumnDef="remove">
              <tr>
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let item"><button mat-icon-button style="font-size: small" (click)="deleteGroup(item.payload, $event)" matTooltip="{{tooltipConst.delete}}"><mat-icon color="primary">delete</mat-icon></button></td>
              </tr>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let item; columns: displayedColumns;" (click)="selectItem(item)" [ngClass]="{highlighted: item.highlighted}"></tr>
          </table>
          <mat-paginator #paginator [pageSizeOptions]="[10, 20, 40]" [length]="course?.taskGroups.length" showFirstLastButtons></mat-paginator>
        </div>
      </mat-card-content>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <div fxLayout="row">
          <mat-expansion-panel class="mat-elevation-z" hideToggle="true" style="width: 100%;" (opened)="initPanel()">
            <mat-expansion-panel-header>
              <mat-panel-title *ngIf="!selectedItem">
                Lägg till
              </mat-panel-title>
              <mat-panel-title *ngIf="selectedItem">
                Ändra
              </mat-panel-title>
              <mat-panel-description *ngIf="!selectedItem">
                Skapa ett nytt moment
              </mat-panel-description>
              <mat-panel-description *ngIf="selectedItem">
                Redigera befintlig
              </mat-panel-description>
              <mat-icon matTooltip="För statistik använd kliniskt / övningsarbete samt kryssa i handledare" color="primary" style="margin-right:20px">info</mat-icon>
              <mat-icon *ngIf="!selectedItem" color="accent">add_circle_outline</mat-icon>
              <mat-icon *ngIf="selectedItem" color="accent">edit</mat-icon>
            </mat-expansion-panel-header>
            <form>
              <div fxLayout="column">
                <mat-form-field>
                  <input matInput [formControl]="groupName" placeholder="Namn" required>
                </mat-form-field>
                <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center">
                  <mat-form-field>
                    <mat-select name="typeSelector" placeholder="Typ" required [(ngModel)]="selectedType">
                      <mat-select-trigger>
                        {{tooltipConst.getTaskGroupType(selectedType)}}
                      </mat-select-trigger>
                      <mat-option *ngFor="let type of groupTypes" [value]="type"><mat-icon class="mat-table-icon">{{constants.getTaskGroupTypeIcon(type)}}</mat-icon>{{tooltipConst.getTaskGroupType(type)}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div fxLayout="column" fxLayoutAlign="end end">
                    <!--<mat-checkbox name="isMandatory" [(ngModel)]="isMandatory">Obligatorisk</mat-checkbox>-->
                    <mat-checkbox name="isSupervised" [(ngModel)]="isSupervised">Handledare</mat-checkbox>
                  </div>
                </div>
              </div>
            </form>
            <mat-action-row>
              <button mat-button color="primary" (click)="initPanel()">Ångra</button>
              <button mat-button color="accent" *ngIf="!selectedItem" [disabled]="selectedType === null || !groupName.valid" (click)="createGroup()">Lägg till</button>
              <button mat-button color="accent" *ngIf="selectedItem" [disabled]="selectedType === null || !groupName.valid" (click)="updateGroup()">Spara</button>
            </mat-action-row>
          </mat-expansion-panel>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
  <div fxLayout="column" fxFlex="40">
    <task-group-details *ngIf="course" [taskGroup]="selectedItem?.payload" [courseTitle]="course.name"></task-group-details>
  </div>
</div>
