<mat-expansion-panel [disabled]="item.result.taskGroupResults.length == 0" hideToggle="true" style="margin: 5px" (opened)="panelOpen = true;" (closed)="panelOpen = false">
  <mat-expansion-panel-header [collapsedHeight]="'170px'" [expandedHeight]="'170px'" style="padding: 5px" div fxLayout="column" fxLayoutAlign="space-between stretch">
    <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="10px" fxFlex="100">
      <div style="padding: 5px" fxFlex="25" fxLayout="column" fxLayoutAlign="space-between stretch">
        <div class="mat-elevation-z0" style="padding: 10px;" fxFlex="100" fxLayout="column" >
          <div fxFlex="60" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon fxFlexAlign="start" class="example-header-image" color="accent" *ngIf="panelOpen == false">expand_more</mat-icon>
            <mat-icon fxFlexAlign="start" color="accent" *ngIf="panelOpen == true">expand_less</mat-icon>
            <div fxLayout="column" fxLayoutAlign="space-between start" matTooltipClass="allow-cr" matTooltip="{{item.result.user.firstName}} {{item.result.user.lastName}}&#13;{{item.result.user.email}}">
              <mat-label style="font-size: large;"> {{item.result.user.firstName}} {{item.result.user.lastName}}</mat-label>
              <mat-label style="font-size: small; color:gray">{{item.result.user.email}}</mat-label>
            </div>
          </div>
          <div fxFlex="40">
            <mat-divider [inset]="true" style="padding-left: 10px; padding-right: 10px"></mat-divider>
            <mat-card-content>
              <div fxLayout="row" fxLayoutAlign="center center" style="margin-top: 15px" fxLayoutGap="10px">
                <div fxLayout="column" fxLayoutAlign="center center">
                  <mat-icon color="primary" [matBadge]="item.filedCounter" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupState('Filed')}}" [matBadgeHidden]="item.filedCounter === 0">{{constants.getTaskStateIcon('Filed')}}</mat-icon>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                  <mat-icon color="primary" [matBadge]="item.startedCounter" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupState('Started')}}" [matBadgeHidden]="item.startedCounter === 0">{{constants.getTaskStateIcon('Started')}}</mat-icon>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                  <mat-icon color="primary" [matBadge]="item.rejectedCounter" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupState('Rejected')}}" [matBadgeHidden]="item.rejectedCounter === 0">{{constants.getTaskStateIcon('Rejected')}}</mat-icon>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                  <mat-icon color="primary" [matBadge]="item.signedCounter" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupState('Signed')}}" [matBadgeHidden]="item.signedCounter === 0">{{constants.getTaskStateIcon('Signed')}}</mat-icon>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                  <mat-icon color="primary" [matBadge]="item.notAttendedCounter" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupType('Absence')}}" [matBadgeHidden]="item.notAttendedCounter === 0">{{constants.getTaskGroupTypeIcon('Absence')}}</mat-icon>
                </div>
              </div>
            </mat-card-content>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" fxFlex="75" style="margin: 10px; padding-left: 5px; overflow-x: auto; overflow-y: hidden;">
        <mat-card class="mat-list-item" *ngFor="let week of weeks" (click)="onWeekSelected(week, $event)" [ngClass]="{highlighted: week.highlighted}" style="padding: 10px;">
          <div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="5px">
            <div fxLayout="column" fxLayoutAlign="space-between center" fxFlex="60" fxLayoutGap="5px">
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                <mat-icon color="primary" [matBadge]="week.filedCounter" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupState('Filed')}}" [matBadgeHidden]="week.filedCounter === 0">{{constants.getTaskStateIcon('Filed')}}</mat-icon>
                <mat-icon color="primary" [matBadge]="week.startedCounter" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupState('Started')}}" [matBadgeHidden]="week.startedCounter === 0">{{constants.getTaskStateIcon('Started')}}</mat-icon>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                <mat-icon color="primary" [matBadge]="week.rejectedCounter" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupState('Rejected')}}" [matBadgeHidden]="week.rejectedCounter === 0">{{constants.getTaskStateIcon('Rejected')}}</mat-icon>
                <mat-icon color="primary" [matBadge]="week.signedCounter" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupState('Signed')}}" [matBadgeHidden]="week.signedCounter === 0">{{constants.getTaskStateIcon('Signed')}}</mat-icon>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center">
                <mat-icon color="primary" [matBadge]="week.notAttendedCounter" matBadgeColor="accent" matBadgeOverlap="true" matBadgeSize="small" matTooltip="{{tooltipConst.getTaskGroupType('Absence')}}" [matBadgeHidden]="week.notAttendedCounter === 0">{{constants.getTaskGroupTypeIcon('Absence')}}</mat-icon>
              </div>
            </div>
            <mat-divider [inset]="true"></mat-divider>
            <div fxLayout="column" fxLayoutAlign="space-around center" fxFlex="40">
              <mat-label style="font-size: small; font-weight: bold" matTooltip="Vecka">{{week.nbr}}</mat-label>
              <mat-label style="font-size: xx-small; color: gray">{{week.duration.start | date : "MM.dd"}}-{{week.duration.end | date : "MM.dd"}}</mat-label>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <mat-tab-group dynamicHeight animationDuration="0ms">
      <mat-tab label="Logg" *ngIf="selectedDuration">
        <ng-template matTabContent>
        <div style="padding-top: 5px; padding-bottom: 5px">
          <div fxLayoutAlign="space-between start" fxLayoutGap="10px">
            <div fxFlex="100" *ngIf="selectedWeekItem !== null && selectedDuration !== null">
              <result-list [fcResult]="item.result" [currentUser]="currentUser" [selectedDuration]="selectedDuration" (refreshResult)="onRefreshResult($event)"></result-list>
            </div>
          </div>
        </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Översikt">
        <ng-template matTabContent>
        <div style="padding-top: 5px; padding-bottom: 5px">
          <result-overview [fcResult]="item.result" [currentUser]="currentUser" (refreshResult)="onRefreshResult($event)"></result-overview>
        </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-expansion-panel>
