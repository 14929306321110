import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthUserDto } from '../models/AuthUserDto';
import { LoginDataService } from './login-data.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    public hide: boolean = true;

    public loginForm: FormGroup;

    constructor(private authService: AuthService, private fb: FormBuilder, private dataService: LoginDataService) {
        this.initForm();
    }

    ngOnInit() {

    }

    public login(): void {
        this.authService
            .login({
                Username: this.loginForm.controls["userName"].value,
                Password: this.loginForm.controls["password"].value,
                Token: ""
            }).subscribe(result => {
                if (result) {
                    // Clears on browser close
                    sessionStorage.setItem('currentUser', JSON.stringify(result));
                    this.dataService.changeMessage({ loggedIn: true });
                    this.loginForm.reset();
                }
            });
    }

    public onClick(event: any) {
        if (event.detail === 0) return;
        this.hide = !this.hide;
    }

    private initForm(): void {
        this.loginForm = this.fb.group({
            password: '',
            userName: '',
        });
    }

    @HostListener('document:keydown.enter', ['$event'])
    onEnter(event: KeyboardEvent) {
        event.stopPropagation();
        if (this.loginForm.valid) {
            this.login();
        }
    }
}
